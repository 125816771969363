import classes from './ProductComponent.module.css'
import React from 'react'
import img2 from '../../Assets/img/WhatsApp Image 2023-10-30 at 13.58.52_8a29cbb3.jpg'
import img3 from '../../Assets/img/WhatsApp Image 2023-10-30 at 13.58.52_db01e6d5.jpg'

export default function ProductComponentSmall({product}) {
      return (
            <div style={{maxWidth:"270px",maxHeight:"110px",display:"flex",alignItems:"center",justifyContent:"center" }}  className={classes.prodContainer + " product-widget  d-flex align-items-center py-2 my-3 mx-auto"}>
                  <div className={classes.prodImage +" "}>

                        {/* <img src="./img/" alt=""/> */}
                        <img src={product?.image1?product.image1:img3} alt={"product"} />
                  </div>
                  <div className={classes.prodBody + " px-3 product-body"}>
                        <p className="product-category px-3 mx-1 my-1">{product?.category}</p>
                        <h3 className="product-name px-3 mx-1 my-1" ><a href={"/preview/"+product?.id+"?p="+product?.id}>{product?.title}</a></h3>
                        <h4 className="product-price px-3 mx-1 my-1">{product?.newPrice} fcfa{ product?.oldPrice>0 &&<del className="product-old-price">{product?.oldPrice}</del>}</h4>
                  </div>
            </div>
      )
}
