import { Close, List, ListAlt, Visibility } from '@mui/icons-material'
import classes from './ProductAdd.module.css'

import React, { useEffect, useState } from 'react'
import { Button, Input, ListItemSecondaryAction, Rating, TextareaAutosize } from '@mui/material'
import ImgComponent from '../../../cropeComponent/imgComponent'
import Cropper from 'react-easy-crop'
import ProductComponent from '../../../productsComponent/ProductComponent'
import LoaderComponent from '../../../utilities/loadingComponent/LoaderComponent'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import useSilverHook from '../../../../useSilverHook'
import { addDoc, collection, doc, setDoc } from 'firebase/firestore'
import db from '../../../../firebase'
export function getproducts(products, newProducts, category) {
      if (newProducts) {
            let returnArr = []
            for (let index = products.length - 1; index > 0; index--) {
                  const element = products[index];
                  if (category?.length > 0) {
                        if (category === element.category) {

                              returnArr.push(element)
                        }

                  } else {
                        returnArr.push(element)

                  }

            }
            return returnArr

      }


      let returnArr = []
      for (let index = 0; index < products.length; index++) {
            const element = products[index];
            if (category?.length > 0) {
                  if (category === element.category) {

                        returnArr.push(element)
                  }

            } else {
                  returnArr.push(element)

            }

      }
      return returnArr

}
export function getMaxPrice(products) {

      let returnval = 0
      for (let index = products.length - 1; index >= 0; index--) {
            const element = products[index];

                  returnval = Number(element.newPrice)>returnval?Number(element.newPrice):returnval
      }
      return returnval

}
export function filterProducts(products, rangefrom, rangeto) {
      let returnArr = []
      for (let index = 0; index < products.length; index++) {
            const element = products[index];
            if (element.newPrice <= rangeto && element.newPrice > rangefrom) {
                  returnArr.push(element)
            }
      }
      return returnArr

}
export default function ProductAdd({ products, setproducts,productEdit }) {

      const url = "http://eccomerce.eternalcompanyltd.com/";
      // const url = 'http://localhost/ecomerce/'
      const [form, setForm] = useState(()=> productEdit?productEdit:{
            title: "",
            image1: "",
            image2: "",
            image3: "",
            category: 2,
            rating: 5,
            keywords: "",
            shortDesc: "",
            longDesc: "",
            endpoint: "addproduct",
            oldPrice: 0,
            newPrice: 0,
            ALL: ""
})
      const [submiting, setsubmiting] = useState(false)
      const [preview, setPreview] = useState(true)
      function setImage(i) {
            console.log(i.target.files[0])
            const image = i.target.files[0]
            const reader = new FileReader()
            reader.onload = () => { }
            reader.readAsDataURL(image)


      }
      function setImage1(i) {
            console.log(i.target.files[0])
            const image = i.target.files[0]
            const reader = new FileReader()
            reader.onload = () => {
                  setForm({ ...form, image1: reader.result })
            }
            reader.readAsDataURL(image)
      }
      function setImage2(i) {

            console.log(i.target.files[0])
            const image = i.target.files[0]
            const reader = new FileReader()
            reader.onload = () => {
                  setForm({ ...form, image2: reader.result })
            }
            reader.readAsDataURL(image)
      }

      function setImage3(i) {

            console.log(i.target.files[0])
            const image = i.target.files[0]
            const reader = new FileReader()
            reader.onload = () => {
                  setForm({ ...form, image3: reader.result })
            }
            reader.readAsDataURL(image)
      }
      // function submitForm(e){
      //       e.preventDefault()
      //       setsubmiting(true)
      //       console.log(new FormData())
      //       let formData = new FormData()
      //       formData.append("url", url)
      //       // formData.append(form)
      //       for (const key in form) {
      //             if (Object.hasOwnProperty.call(form, key)) {
      //                   const element = form[key];
      //                   console.log(element)
      //                   // formData.append(element)

      //             }
      //       }
      //       let data = new URLSearchParams(form).toString()
      //       console.log(data)
      //       // setfileForm(formData)
      //       fetch(
      //             url,
      //             {
      //                   method: "post",
      //                   body: data,
      //                   headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      //             }
      //       ).then(response => response.text())
      //             .then(
      //                   result => {
      //                         if (result.includes("success")) {
      //                               // on success
      //                               alert("succes");
      //                         }

      //                         console.log(result)
      //                         // setfile('')
      //                         setsubmiting(false)
      //                   }
      //             ).catch(() => {setsubmiting(false)})
      //       console.log(form)
      // }

      function addProduct(product) {
       
            let warn = true
            for (let index = 0; index < products?.length; index++) {
                  const element = products[index];
                  if (element?.title === product?.title && !productEdit) {
                        alert("item already exist")
                        console.log("item already exist")
                        return
                  }
                  if (element?.image1 === product?.image1 && warn) {
                        alert("warn image exist")
                        warn = false
                        console.log("item already exist")

                  }


            }
            handleNew()
            // setproducts([...products, form])
            
      }
      async function handleNew(){
            // const docRef = doc(db,"eccomerce","id123")
            // const payload = {name: "new name",value:"nvalue"}
            // await setDoc(docRef,payload)
            if(productEdit){
                  
                  const docRef = doc(db,"eccomerce",productEdit.id)
                  await setDoc(docRef,form)
                        alert("updated succesfully")
                        return
                  }
                  
          
            const collectionRef = collection(db,"eccomerce")
            await addDoc(collectionRef,form)
            alert("product added")
            console.log(form)
            console.log(products)
            console.log("product added")
            // addDoc()
          
          }
      function submitForm(e) {
            e.preventDefault()
            addProduct(form)

      } 
      const modules = {
            toolbar: [
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  [{ font: [] }],
                  [{ size: [] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                  ],
                  ["link", "image", "video"]
            ]

      };
      useEffect(() => {
           
            setsubmiting(false)
            console.log("----added product",products)

            return () => {

            }

      }, [form])
      useEffect(()=>{
            setForm(productEdit)
            console.log(
                  "==================="
            )
      },[productEdit])

      const test = `data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAoKCgoKCgsMDAsPEA4QDxYUExMUFiIYGhgaGCIzICUgICUgMy03LCksNy1RQDg4QFFeT0pPXnFlZXGPiI+7u/sBCgoKCgoKCwwMCw8QDhAPFhQTExQWIhgaGBoYIjMgJSAgJSAzLTcsKSw3LVFAODhAUV5PSk9ecWVlcY+Ij7u7+//CABEIAMgAyAMBIgACEQEDEQH/xAAwAAACAwEBAAAAAAAAAAAAAAAABAIDBQEGAQEBAQEBAAAAAAAAAAAAAAAAAQIDBP/aAAwDAQACEAMQAAAC2QAAAAAADkdKq4ZFejJVbQBQAAAAAAAAAAHI7UnThdVFbNY6r21ri/S6xOUPuZUk1xB/pADQAAAAADkRzSrkmt1rWkrLKxyrlOpdoZAdYg0Z/JMS8sz3MNbuZp7yAbAAABnN53NxbrrUVm0bG0OSmo9ZukQNeu3L7fQrSUr0tXaXsZZzmMTXIT6wAoCMZ681uVrfur2ShVZLdbZ3lh/MkzuNyz3+l4kzKa8/HYyJp2S7GspNp25ug/larIB0FF6eSdN1WNBbRuUupbOdUr7S05Kspyyi5TSaBVbvduFu52mZo5zc1TG9ZG9bK0M5YA6hF5LBNN1LO+GrlV3ewduxqMldZF4nGRuXhte6vZisqMp9LlOJ9mtHMkDj6D8wyB1CzMMspN+zlrMY0Oc6q3ExGkpc6y9FizJLrVnQpbeh0mpkN5LQ+gZ1LsLS/UztVz6B1EOLcL2In5NOEJ4Ac1Ajz1QnOjnbTncDtUvTK6WLOdzK9ktxnGp1ToqkyyB6JRK+njV67LfNaZRW6HY5HN3YMc01J5l+
      Y5CN/FGq3hCfTIAxYSSb2sZos6y8qPTmxVjuXJh0UZ+vVlkrvQzpIvruoAKSiDbGbLOdYzm+csOs4KjRSsm+7lJcdoAdAAABxN0yyoa9OGdxuuWnl/SgbYrPabLADYAoAAAAAAAAAAAAAAAAAAAAAAAAD//EAAL/2gAMAwEAAgADAAAAIfPPPPstOPPPPPPPPiF/VQnmPPPPOvz7+Ia/KsvPPELXwo3uDxXdvPkV7SE41sRTUvLHcRky0cNeNZfPsJTSM5ZhmUpfLpmM3YelPvkh/GItu4pLlDWOsvoLXjyo1x5fFkA9xCht/LF6tjMcfPPLjjwwBjvPPPPPPPPPPPPPPPP/xAAC/9oADAMBAAIAAwAAABAAAAAQAowAAAAAAAL8W2MHqsAAAAZkGdO4wkgoAACkMChuvgxvIgASsOwtDTpe1kwAAJVGkdJvZREQBS9JXI7J8PeaMBTXJ+BsGfQN+gDnoIcFguxJn7o33H2R/wBfsKDT2wR729w4muJO00+AAAA5yLsrSAAAAAAAAAAAAAAAA//EADgRAAICAQIDBAYGCwAAAAAAAAECAAMEERIFITETIkFhECAyQlFSBhQVIzNyFiQwNEBDU4GCobH/2gAIAQIBAT8A9QsF6mHIpXq4n1mn5xFtrbow/YEhRqTLMssStQ184amb233QUVj3YaK/khoX3SVMFmRR471lWQlo5dfVZgo1Mex8l9BqKx/uLWNAByE2bWE1TXrAyTahjV/CPXtO+saPMe8Wrp7w6+plPvYVL
      /lK093whIQR7NYAzOFhqad5J2hbQQpoNY+tLixR+aIwdQR6GbapMq7xe35oi6ANrLzu0Eay6g6NzXwMF/aU7/FP+SqxbK94m7e21hHUoYrbkEsUNqDMJ+61Z930ZR0peVDuIIQFTlK1DtzmTjsBqg3D5ZjWIweoroTKXegvWegMVg3eEsGtesqIGsbrrMbllOPiPRmfgNE9hIykD2pR
      7RjttEtDG3dtH9poGGhHelO5Hes+EJ+6MRtDGcHSU/vZ/L6L131MIb6qqtbG2y3jNCnRFLT7cu91BEy810DmtDDxist94hU+Uoy8a0grb3oERiHBlpAG0RW2HXSa6mYo3X2N8OXo4nxVMYGuvm8VL86xjv5+cdCjFT1ExOx7Ze29mV5OOCEVwV8ABOI4VFQ3izQnwnDK6yzPYe6JTcuhNbaLLuLPVaVeruxOLYrddwh4nhgcrOcq4pSg2VBndjKe0NY39ZlYGJi02WuN7eZmPRk3PrSDKeEByTdZ3vgIOE4g8CZRh41DblSZXDfrLa9odfOW8Oy6A4TvL5SrPuxkFZXpMrMbJbUj0ZFNNddb1vqSOc4VbgUaPa2rz7Sw/wCqJlYz5tqoeVa9fOUY9VCbUUAS/HOvaV8mitz0bkfR0mssxqb/AG61Mv4TjAahypg4FdYNUcaec/R/K+YSj6PhSDbZE4biKoHZD1LKEt6iHFuT8N9R5z9YXrXrN15/kwVZT/BZXhqp1c7jAAP4H//EACYRAAMAAgEEAgICAwAAAAAAAAABAgMREhAhMUEgMhMiQFEjQlL/2gAIAQMBAT8A/gJN+CcLfkWKF5OGJ+ysK9DxufnMO2frikVtxRxyM/HaOVoWTfai8Xml8Um2kbnHJ2p7JiZe2PNE1xFnhm5scTO2RTp6fgywpba+GFbezJXJ60NTGPuXlrwntDfNb/2RNckLc90RatGRapj/AHx7+ENrH2FuqWzM9QysftENLaZL4tk95MT1ejNO9EfRyV2p9cX1RNKn9TP9OjT/
      AKHJjbXZkr/Ki52jHDnezJ931xX+pyplJ0u7OMo4NDRrRhlt8io5o8IrvTfSI33YpUrpW9PXSGyxox7ldmc69ou6a1o4P2Mmqb0NpeRVyekKK/6Kxt+xRcjHKYl1tU/BxomuI22Lae0Y8vpipPo0VCKrh7Pyo/Kh5P6OdfFVS8MWWkfmoeSn/D//xAA/EAABAwEFBQQGCAYCAwAAAAABAAIDEQQSITFBEBMiUWEgMjNxFCNCUoGRMENicqGxwdFAUFNUc/AkgpLh8f/aAAgBAQABPwL+RVRniHthelRdV6XF1QtcXNCaN2Tx/BPmZHmfgnWp7sGCicyTOR9PMr1A9pzvuhVZpA8/FXm/2x/8uSqzWGQeS9QfrCPvBBszMWGo+yaplrcO+EyRkndP0pIaKlSWlzuGNFrGeIcfdGaMrhyjHTEq833an7S3j/e/0J8cjAC7IoxPbGJDkU2OZzLzQaLeO8/NBza6t6hbxzu+A8cxmgyvFE6v5hRWrR/z+je8MFSnOfaHcgFfDcI8Bq/9lfp3M+eqfG+Ol4UqrPjMwdVO0Whr7nejdRM/5FjLfaYrZnZ4G8v/AEmhzHMYG+rDM+qs8V60XPdOPwVqiBkhuAcZUjDHMWMJJA0QeM8ncwqiXB9A/R2jlHK6F112SBBFR9A4gCpTnOtD/sj8E97SLowjHzcmtfM6jR+wVluMnfGaF2hVpYG13s16X2QFZcJ2XsKVRtzxOdY66Jlp3cz5GMwdovSXb/fXBVR2h4n3hrTkm2iJvpLxW+7JWYt9Gje72AVZjLJaLw1OPkrSLO4vo8B7c+qY/R2X+5JrrwDHn7r/ANFFI6F112X0FokL3btqfQAxg4Dvu/RcUmQwGnJRWqGO6xrcDmVPCbNIHN7t
      cFJbIeF7Y6y0+SLnSOLnZlU7N590tDjQ6KOdkEDrviFAc1DA2aJ1K3wmmnC/LXoh6wXD4jcuoVll9g/DtTv3bCdUDu2GT2jg3907MMGP7qKC0wGrbpHJT2O/xsF06tRmkMO70VNjbNLdLrtAoLNHK2pea6hegw83J1gHsvUlmljzGHTbBEJX0rRS2u7SKz6aqdz3vvuiLEwnCnebi1O9mVuv4FRvvtDuzaDvJQweSlfxkg9wXWqpGWajtDZhclN12jhgpjPG50RmLgo8M8k6KnkrohuSMdXmo3iRtU6tmnqMv0QIcARsvBT2QPxZgUQQaHPZZrkEEkxFSDRQWqSeTdvaLpXhyOAPdcQoqG9Ho8Vb58lZH5s7DjQE8kw03kvIfiU/ANb/ALiopKwUiaCW5tKnngjNx0ILqckOapVRXSwXX1dqCi0Y0HmFBJun3Va47wBVjkzjOiPJeSb5q2QXxfHeGyGYR3mvbVjs0LRDGCLPES7yRjlZxSNIqdUx1ADqw1R4J7wyrX4HsWk0icj4LG++/wDJMjM8rsetVZ4pnOvxEcOqtkge5tWUkHe2WKIPeXHIKeye0xCVzcH8Q56oxte0lmP5qKQWiLrqneqka8J8lJIORqjzVemy0R7uUjTTZPaDZS2ONgyUslpmbxg3Rjko86cxzojjFEehb8lGb0bT022zuD7yd9SOTCcqqzytieb2RFE6dtYobOaNriVa3B1pdTTDZYm0hHU7JYA7EK4+I3moScW8bg/2h7ylpIy+3ulHjs4+y6iifvGY55FBDJW9uDHfDY22vY0AsDqap1otM7H8Dbmqjwc3zQ8Fw5Sqy+C3bbMmKXNv+HbTZZvBj8tjnNbi40TpYHe0nQ3jVjwrxiccPvt/UJrWm+1p4X
      tvBRcDh1wOxuSt3g/9tjZbHdAfGa0V6D0W0GEU5pqHcn++FZPC+O22d1vmpPq/8VM6bLO2z7jiu/artshrAzY50G8o8gnqrQReDWs4q6INN6lAUGO3hdIw3VjC8SMdeYDj0r0UrR3m912PxUbr8YKGBCt54GDrsDC7JpKvODS0HA5poxHmh4ch5yKy+F8dtqFYvJexCfdcR80RQkIRl+TSU2xzHMAea9D5yhQBsLS2/X4LeN5p9nbI6t9TRh7SQOLmvBdf473UJ0xkpQlAx4RPxJPxCobN6uTwnZO5KLhEo1a6qFKAhWx96Wnu7LLaYomFr8MU91973UpUqPvj/wCLKCPqSVAKRM8tsjbzHDmFGwyRyM+RW5iBvO4j+CvHIYeXYqz7RPQJpbJgxxr1QKvlARVB3YqOSuxF18AX0QZKskbwkLjscwrjHkjMIWOAx9zyPZZ3XczhmnisjYxoA3sOcGouJ7OhPJOkEHCBV5zTMY30bcw7wTAQGimYVdjeJ12nxV+MGm/W8EnAOMap1livUDyPxRscoyofJOje3vMI2xDEe7GL3xVmbfkLzsDqpz7qOOySZwfRMNRtxH7KrQaiAV807eS8OnIK/cZvDSuWGqaXHQDZJI6MMY3PVZ4bltemCLxBwMFXaoNc3F2uuy+7mjdPeY0/BXYq13QXq8Ru880HXe6AFi8oCgW7c7NbrrsdGHICioVcIzoFwf1GfNcH9VnzXBTOvMDVFskjqluAyCy2XcalBwY6pTHNa+t0nmSi8mtGZ5kobTI0GldgFUxl3bK7TYIjqjM1j7pbTqpXzVuuf5EYAo117Akkbk8oWyX2qOTbTC7MFv4oC9i0hw7b4nX0wUCDi1b7ot63Zi8prA3ZNCJB1WVYpRh+Seyn
      exByf+6LS3tBxaagptskHe4kLRA7m1AXu64O8lQjtBhKEQ1T6nBAU7EsLZM0RJAThVv4FXGu8I0+wckY6UBqw9Vcdy/09rdnM4eaYyp4BePPRb0xVrJfcfkonSy5tbTmt23kt21btvJXQPoSAc1JZAcWYdETLHwvbUdcQvU6Xoz0xW7Jykjdl3sMluJP6IPkVuH/ANvr72i3Lx9WwZZlXKZzNH3RVepbiGl55uVJpvL5BR2Voxdif4DNOs8TvZ+SNjGj16G/3gvRJObV6G/3ghY26uKZZ425N/kf/8QAKhABAAIBAwMCBwEBAQEAAAAAAQARITFBURBhcYGRIKGxwdHh8DBQ8UD/2gAIAQEAAT8h/wCEg1ZqfoZ+kT3XpP6k5I9OnC//AIeauGsS+5MRhe+hYZ/h+sK8FjWmrUo9d1bqZ6p6r011laLePukI9nj2iFW9yGXZ2/1cJQasZoTvu+JlXX3PVxCKHh70sYlkzBtXtxKQBGDTHZpCnTQyMrLNLOZcoz0feG+hu6o6FQwS0rLwcQIaK2Di6nB/U09LpYRLP8k70SucrOgcrEyvJ61dYTY4L5Oz3gTfZuEeMfhFIZ1Oalu/56f1QLqQPwm+mELlaO+rMWxBT6zy6FNPxL1lAx2zSvvL2sqGVcXs48Q0ljo/4KkoJrmgvOzliNX3Rz4geobGaGATLp58kATJ2R6RG/FrxWGG4gooa97mg3ZbCy0pQcbRc6SqWXrGjf7mMj2++WfWjzpMgNC3tufZCNn5XdHe+z2giX8ZPLT7sdBs5bu3hHMrStLaR5wLPsy+O1nw8TATeMNNyQEqVKlDKALWtiF399/SWW5PLAsh9dvENpOVDfw4Y11EcintXySztfEpzVg8yzNX3tYs6ec6Z3
      Um0Q2artrKjz00YbxS671tCK4iVUDf8THhYCimf+zN0nkmRt5dFS4agt7+IdSVhT7QrcAMmrHFlLZfuVWbjLxzPwakA7c+FdkfMwdFYhzfPeCRSDRNoObubJ/Mp9znZ+kq61MMcWQk7xcMrb1IlbnJyo5ViWdO6QJr4dmIgoakqaRqJcrN02mVi7wxozBK0lsOTKlvJ8FuaBYvKZPZio3AW5vPhCMTVgTX71waWC1W8wY14hDOnuveJ0F4eIqcM9gWObvRKYW8UKKDdR3d2ZhfBnudOGSI9efy4ZzA1KyrdCNd9aIgtSD8B5liD9Fx5QEw1sPxEOQVwfzAv+b9ugA2PzZkR9JVD8g9Zma1jYsQ7EocMDgHMJF/laRmViFNb9zpVWrPRvp+39SyWLsx5WoQ2PVj58RHfI9Vj5jiX6+XtHLXVGpa+z9YzrQezpZuZ6WYF8cxsFxqS2nT6L8zLt5fDFkuU90z6o+5KG9S9L1qes3oeIDFoblA08esSouwZP3BXLuOIr7F/Xr86zK+vMXnjpRAHT5L0FojvNTy5pg3M7ZzBtRHT0f7zM81p7jpGq/cIDk1ltlTS8Og6wi2t68xVJhVufWbINe8bFfKaPl1M2prqvcb9E35Lahg3fG3Tx6z59NUBopghCRg2RwuNa0f3KwKxjFPJAMNyDD3IyMw0YGz2p8zcJ57f2OlngdaFhwP7mYnkSlaq2bNnmGj3XW8eQ/aK68tUo5bztA1zGqY7FzQB7oH7EuX4Xb2dNjefKdjkGsUDEPFWkfF9wSjdr75MfxBi9p5s557Akv0BLGY+4FdMzizda+0AShUIMrWM5dmdSaDd/6SmeXv17ghO7wU0E1g/k9dvYSowHgVG3rsXQtNKPaEujrL8mIDvZ3li
      AlmieqOcTJLqZrNQy7fqOxy7tD2iqqtrr8GDmwa5a43l5P6GBQHUTMxOhwfDumil7ue0rhe0R4r/QZmprcQD0FSfg6eYJhY7LFwVxsohrRq60T6xX5n0csdQGc2GRL8JG2B9WLUC1Qh3iVb0SDQj3PVXsBvVaMFLBu2mvYipnNKbOE2DuwfdjglOCy7cQda18yBZDql6bayugP5Zn1LCBjIGysZgAqU7y1ZWofsTSXCIJavCVNZatrS4laQBRBtBZuHkZe728v9XHGNXc9ueISraRtK0TWbpbCAKHEHF68u+xK0HjL40lq62SLg3EVBAHfrQd0q4zhLFabsvZiMO6sp62wB1X31+DTb6wn1R+JhWfZAHhhibJKJR8DEmixBuaZUOUdpi0XKek8xz040dGWBBv695H1tp739sqLMJY8/FcEPJCAIe+Gayr3yQRZXcMdYJ1o66Ge8JyYApv8ASGaPgDrVs8Q8Quo5gaIS2678wkPB7XluIA5WGmfha4NZhprz5U+Y+XLxOdYXi7TbHB8a+nRdv59MaQD/ABNQWTMFuWkD8BPsMpt7rnT2mBWmgWpXUsOFr6vM73d9iFe+QPbX3lccfVXNbmR5Jd/BCkA8aIZF+lArx/uhGuA9k1SHkuOyvyn/ALj+IbgzfbwVNGPLn/h//8QAKRABAAICAgEEAgICAwEAAAAAAQARITFBUXEQYYGRIKGxwTDRQFDw4f/aAAgBAQABPxD/AKIGwDlWpvJ5f7prvEn2Zvc+W5T2ehagUsRP+DemnZkULZTWL4PS6Ex/ALiKu3ByOBceVli6tTRyxIuo+bDXDE1KhDTnmMIeBdH7WYbTn7yMJjoplJKDOif5TwitJTVOOcjof7HPk6ha/pZWisYvWVDGtxsU
      IrYxIJKcdSLS0gfRzqd8LLDrC+RY8x5sTiox3PIRWnfghaFG1dIbNqtO5cbgudPkNA3AFqm/2ATTSPA/uGEESx/xHhH7fERmrVFjRVl6scasuSKqCW5Kqi4ELZIKlC6nHZwwRCshOkyvr7TZY4Vz9s+y4W4f6SWfMhEzcojg7+nCPhBZDqLcvFGA6IdQ4WdeNFCHmG1UrYxsUj6utO3lBsxWH+ClY7WJAtXADuTmk4xx6qWEgLRyLbc7uZuwUICe6U8KymYezhKqlsCnpFuJVjNWK/cYloqetlZEAGCtoI8mP9mLdlvgLGPTmA7BQP571ClDQxpTYqkpNZhyVAHIqG1hFpiH8yAA2J+d+gEQmuUYJfCGRQx3VMUu92s4OPIxXsF3083hhkkoxIAlLoB+iAuUlPQyUeXV62bhSN1ZO9eEZ6UVcivayx+Ed36i0TKgXWWlQwZhZA3uRxKISurkWvyFF/IpcinK8OE8k1SiytFBauIcD+XMINroqg/oIn0CKzRkEILqalLto8y1srW77kM7BQEkuf1Y7PtxMLe5XJ6IYHO6RsGyGzFSK79oBRvqAW3IQx8YJlEK1pa3hjlQ0c3fi8uKiYMEBiLRYjTdxg6WhFdiTU+qa9hNRjpgud992qI+eAEe1zAPEaYxtMOQYOKnXQwaljg0Qydj6ORxGN8w+9lOJuvuicMyEt1KRQ4src4NQiUmARWYgrYp9kPaPoWOgwn+4/At2J8Fyx6uj4sVRcOBrFp0xhGFg6jaF6H1CmbIVXC45jcDLOap9gdIeLGxB5L5IiK4+oBx20KxEWUxBuecQYAUMXiUssdLBfaw64SwnGKlGDRtwJ+UqwKiawrshi5BmlKaMgFlrH9yrWytqGvX3dX7sV5WcESQUKhfz36IYLKQIQW1r2+4yD8f08wAQHdEDEzyw5Yg8NAmg9v6MR5O5K/soVY9g7CMQp+1JN+UPqH8pTBQtLObzZMZoGkFIZIYivrmUWC0LDjhJPH1AuquFeorB3Z9qirKB0wdz4AWP0zONrXzXqx1z9EqapMCM1aoQTeILVsLOkz1Luri2AP+fQBu04xS3d6k05heHyTFMNG4PaHWEgTZG4JZL7AcWF7Db5lhKtOwz2XFZ2C4aI0yMrNiajCHBX2PJRxQuUCZ4cBnIzzFhZs8TywfpfhNlHia6DT0e1NQehPSPc46qUtNNAEgA1yyk8wzgR5/qS70npUmHuUKt45mHsSisIo0Gs3iGexPsctwByQec5q2DvS8nF/plFIadX9Lj9TsTqWJcL9Rs5gfqMDk2sLAWBWzZ2l4v0DtFfj0JWDTZ7ZeI+6IUIrXoZteUoagNr8ItxS0GJIsGykuKugYNAyMYWT4BZcKslOblfIf4yZ3WGOHbQx9hXjKpGv8DV/oyyusHCgUJggE9n1QbcRULaoSsFNpoQWVJo1swygZcNBA9iOELNKKCU7VJ/5jDlJ1UHETSE0jCU05KyTAR+hOHK9UEIMhrxgov+YSz78QKXV3O7llW5zUJSIuwJYwGtfc7fRImshtWPDmZFhaI3NGLyUWsJDnf2kGeB/8nq9SL8ozWwRhG/5DL4XXpdXEKgS6ICJLVX1uTdx2nvcSgXDzTSRzeqBU9wyIocFuQ01gi6mGy/KADtKDAjrbG4p16/7wd3LDkyPhFzQKna7VgeiVUsxxiAc7tsEIpogb4MoRGg9bgvBN+PxzDsOAbFQHJWUAoN2gcWkfE3FOs2RHokhc12y+pyOfQ9As1k9hsYapekqfZic1mqi0P1fhuZqn/wCMUmiLtfqfyyz1pIMlmavcgFrLtkHPCyobeiKEtZxcxeuYgsAa9VR0Vqmz5HTCi2tTn4SIgzpKPmCmNhKUYAt48S5XICw/NjFYnUSgY2YTSEXobA0FCVqmL9WzaHGBb7weRhtDYL7RYwviZE0DYwODVr+
      Rgu0b5IJm1foIaWCKLlogVIajUKl6g0lytGIPZnYRFZnZkR0vMDjZgqksWsssKOnUaT3Z+k6lVEpM0yoBXooIC2kKWilRVaGsNdTRHXohBarYp4qVZUfdfu/weUCJB9pnZ3tgegAd7eIJACqzNqnRGITBq0YCNEV0zMpIlKuZb7X60Q4B+jGH0D8b8RVTVW9mFN8QHpV6cMyXWoRDiX1UdVv53KOPww5h+IbJoCVYxeXogmM8lv0upQp7o3Hm7ZXVq5gmXZtwHEfWjLQyCdCn5BB/SInyQMM6wxvM6x7PBEOszw+qnHq1lDvCVLEDer5ehD5
      /gxCiYdxgzCDwkhijRW1NA4PYMuhUpZxOFoXUv5CbocLBS6U/EFAKmgIDqGzBoUDlC5CPB7SwtChXYmxlxBKidLd2n9QvxnvftA\/\/AKfSsqv8AFFKRLGIPywmkK+iYAOsIGlfKmImMgyICK6l4V+8oPnPuxOWLVoK7SXqC6DcoSqywNMVES1sDsvNCx3EAx8/QhDAAAKA/wA5iIJ0zLK9wcvhzB/31+jwrPjBYBv2UIyMbo/6L\/\/Z`
      return (
            <div className={classes.container}>

                  {/* Preview btn */}
                  <div className={classes.previewBtn2} onClick={() => setPreview(!preview)}>
                        <List />
                  </div>
                  <div className={classes.previewBtn} onClick={() => setPreview(!preview)}>
                        <Visibility />
                  </div>
                  {/* Form  */}
                  <form onSubmit={submitForm} className={classes.form + ' form py-4 container'}>
                        <div className={classes.imageHolder}>
                              main image holder
                              <img src={form?.image1} />
                              <input type='file' onChange={setImage1} />
                              {/* <ImgComponent /> */}

                        </div>
                        <div className={classes.smallImages}>
                              <div className={classes.smallImage}>image 2

                                    <img src={form?.image2} />
                                    <input type='file' onChange={setImage2} />
                              </div>
                              <div className={classes.smallImage}>image 3

                                    <img src={form?.image3} />
                                    <input type='file' onChange={setImage3} />
                              </div>
                        </div>
                        <div className={classes.inputHolder + " my-2"}>
                              <Input placeholder='title' value={form?.title} onChange={(e) => setForm({ ...form, title: e.target.value })} type='text' required />
                        </div>
                        <div className={classes.inputHolder + " my-2"}>
                              <Input placeholder='new price' type='number' value={form?.newPrice} onChange={(e) => setForm({ ...form, newPrice: e.target.value })} required />
                        </div>
                        <div className={classes.inputHolder + " my-2"}>
                              <Input placeholder='old price' value={form?.oldPrice} onChange={(e) => setForm({ ...form, oldPrice: e.target.value })} type='number' />
                        </div>
                        <div className={classes.inputHolder + " my-2"}>
                              <Input placeholder='short description' value={form?.shortDesc} onChange={(e) => setForm({ ...form, shortDesc: e.target.value })} type='text' required />
                        </div>
                        <div className={classes.inputHolder + " my-2"}>
                              <ReactQuill modules={modules} placeholder='long description/how to use' value={form?.longDesc} onChange={(e) => setForm({ ...form, longDesc: e })}  required />
                        </div>
                        <div className={classes.inputHolder + " my-2"}>
                              <Input placeholder='category' type='text' value={form?.category} onChange={(e) => setForm({ ...form, category: e.target.value })} required />
                        </div>
                        <div className={classes.inputHolder + " my-2"}>
                              <Input placeholder='key words' type='text' value={form?.keywords} onChange={(e) => setForm({ ...form, keywords: e.target.value })} required />
                        </div>
                        <div className={classes.inputHolder + " my-2"}>
                              <Rating style={{ color: "red" }} value={form?.rating} onChange={(e) => setForm({ ...form, rating: e.target.value })} />
                        </div>
                        <div className={classes.submitbtn} >
                              <LoaderComponent loading={submiting} />
                              <Button defaultValue={"save"} variant='contained' type='submit' style={{ background: "green" }} disabled={submiting}>{ productEdit? "update":"add"}</Button>
                        </div>
                  </form>

                  <div className={preview ? classes.previewHide : classes.preview}>

                        <div className='mx-auto my-4'>
                              <ProductComponent addToCard={() => console.log("NOTHING TO ADD")} product={{

                                    title: form?.title,
                                    img: form?.image1,
                                    category: form?.category,
                                    rating: form?.rating,
                                    newPrice: form?.newPrice,
                                    oldPrice: form?.oldPrice

                              }} />
                        </div>
                  </div>
            </div>

      )
}
