import { Divider } from "@mui/material"
import styles from "./style.module.css"
import { NavLink } from "react-router-dom"


export default function Footer() {
      return (
            <>
                  <div className={styles.container}>
                        <div className={styles.linkSection}>
                              <div className={styles.linkContainer}>

                                    <div>Whatsapp</div>
                                    <div>+237651773304</div>
                              </div>
                              <div className={styles.linkContainer}>
                                    <div className={styles.head+" cyan"}>Boutique</div>
                                    <div className={styles.power}><NavLink to={'/shop'}>Shopping</NavLink></div>
                                   <div className={styles.power}> <NavLink to={'/login'}>Compte</NavLink></div>
                                    <div className={styles.power}>commande</div>
                                    {/* <div className={styles.power}>Download</div> */}
                              </div>
                              <div className={styles.linkContainer}>
                                    <div className={styles.head}>Client</div>
                                    <div>Abonnez-vous pour recevoir des mises à jour!</div>
                                    <div>
                                          <div>Name*</div>
                                          <input className={"form-control"} style={{outline:"none",backgroundColor:"transparent",color:"white",border:"1px solid grey"}}/>
                                    </div>
                                    <div>
                                          <div>Email*</div>
                                          <input  className={"form-control"} style={{outline:"none",backgroundColor:"transparent",color:"white",border:"1px solid grey"}}/>
                                    </div>
                                    <button className={"btn form-control  btn-light bg-cyan"}>Subscriber</button>
                              </div>
                        </div>
                        <hr />
                        <div className={styles.copyRightSection}>

                              <div style={{fontWeight:"bold"}}>Copy right c 2023</div>
                              <div className={styles.powerContainer}>powered by: <nav className={styles.power}>REACT DEVS</nav></div>

                        </div>
                  </div>
            </>
      )
}