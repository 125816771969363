import { CallMissedSharp, Label } from '@mui/icons-material'
import classes from './Admin.module.css'
import React, { useEffect, useState } from 'react'
import ImgComponent from '../../cropeComponent/imgComponent'
import { Route, Routes } from 'react-router'
import { NavLink, useSearchParams } from 'react-router-dom'
import ProductComponent from '../../productsComponent/ProductComponent'
import ShopComponent, { getCategories } from '../../ShopComponent/ShopComponent'
import ProductAdd from './addProduct/ProductAdd'
import DisplayProductsComponent from './disPlayProducts/displayProductsComponent'
import EditProductsComponent from './editProducts/editProductsComponent'
// import firestore from '@firebase/firestore'

export default function Admin({ setloggedin, products, setproducts }) {
      const [params] = useSearchParams()
      const [catego,setcatego] = useState('')
      const [productDrodwn, setproductDrodwn] = useState(false)
      const [selected, setselected] = useState("dashboard")
      function toggleProddropdwn() {
            setproductDrodwn(!productDrodwn)
      }
      function setactive(val) {
            if (val === selected) {
                  return classes.sideItem + " " + classes.active + " "
            }
            return classes.sideItem
      }
      function activcat(cat){
            console.log(classes.dropdownItem+" "+classes.active + " active border my-4 "+catego)
            console.log("-----------dropdown stuff",classes.dropdownItem)
            console.log(products)
            if(catego == cat?.toString()){
                  
            return classes.dropdownItem+" "+classes.active + "   "+catego
            }
            return classes.dropdownItem
      //    return  classes.dropdownItem+" "+ params.get("catigory")===cat?classes.active:' '
      }
      useEffect(()=>{
            console.log("reloaded")
            setcatego(params.get("catigory"))
      },[params,catego])
      return (
            <div className={classes.adminContainer + ' w-100 border'}>
                  <div onClick={() =>{window.location.href = "/"; setloggedin(false)}} className={classes.adminHeader}><nav >Tableau de bord d'administration</nav> <div className={classes.logoutbtn + " btn btn-danger p-3 my-auto"}>Deconnectee</div></div>
                  <div className={classes.adminBody + " "}>
                        <div className={classes.adminSideBar}>
                              <div className={() => setactive("active")}>Tableau de bord</div>
                              <div onClick={() => setselected("customer")} className={classes.sideItem + " my-2 " + setactive("customer")}><Label className='mx-3' /><div className='p-2 m-1'>Clients</div></div>
                              {/* <NavLink to="/categories"> <div onClick={() => setselected("categories")} className={classes.sideItem + " my-2 " + setactive("categories")}><Label className='mx-3' /><div className='p-2 m-1'>Categories</div></div></NavLink> */}
                              <div onClick={() => { toggleProddropdwn(); setselected("produit") }} className={classes.sideItem + " my-2 " + setactive("produit")}><Label className='mx-3' /><div className='p-2 m-1'>Produits</div></div>


                              <div className={classes.dropdown}>
                                    <div style={{color:"black"}}>categorie</div>
                                    { productDrodwn && getCategories(products).map((cat) => 
                                    <NavLink to={'/shop/?catigory=' + cat}  className={()=>activcat(cat)+" my-1"}>
                                          <div className={()=>activcat(cat)}>
                                                {cat}
                                          </div>
                                    </NavLink>)}
                              </div>
                              <NavLink to="/edit" ><div onClick={() => setselected("edit")} className={classes.sideItem + " my-2 " + setactive("edit")} style={{ color: "green" }}><Label className='mx-3' /><div className='p-2 m-1'>Modifier les produits</div></div></NavLink>
                              <NavLink to="/product" ><div onClick={() => setselected("add")} className={classes.sideItem + " my-2 " + setactive("add")} style={{ backgroundColor: "green" }}><Label className='mx-3' /><div className='p-2 m-1'>Ajouter des produits</div></div></NavLink>
                        </div>
                        <div className={classes.adminContent}>
                              <Routes>
                                    <Route path='/categories' element={<ImgComponent />} />
                                    <Route path='/product' element={<ProductAdd products={products} setproducts={setproducts} />} />
                                    <Route path='/shop' element={<DisplayProductsComponent catego={catego} products={products} setproducts={setproducts} />} />
                                    <Route path='/edit' element={<EditProductsComponent  products={products} setproducts={setproducts} />} />
                              </Routes>
                        </div>
                  </div>
            </div>
      )
}
