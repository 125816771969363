
import 'bootstrap/dist/css/bootstrap.css';
import "./login.css"
import { useState } from 'react';
// Put any other imports below so that CSS from your
// components takes precedence over default styles.
const Login = ({setloggedin})=>{
    const [form,setform] = useState({name:"",password:""})
    function handlelogin(){
        if(form.name == "admin" && form.password == "adminspecial"){
            setloggedin(true)
        }
    }
    return(
        <>
    <div class="container mt-5">
    <div class="row d-flex justify-content-center">
        <div class="col-md-6">
            <div class="card px-5 py-5" id="form1">
                <div class="form-data" >
                    
                    <div class="forms-inputs mb-4 "> <span>Name</span> <input value={form.name} onChange={(e)=>setform({...form,name:e.target.value})} autocomplete="off" type="text" className='form-control' />
                        <div class="invalid-feedback">Password must be 8 character!</div>
                    </div>
                    <div class="forms-inputs mb-4 "> <span>Password</span> <input value={form.password} onChange={(e)=>setform({...form,password:e.target.value})} autocomplete="off" type="password" className='form-control' />
                        <div class="invalid-feedback">Password must be 8 character!</div>
                    </div>
                    <div class="mb-3"> <button onClick={handlelogin}  class="btn bg-c w-100">Se connecter</button> </div>
                </div>
                <div class="success-data" >
                    {/* <div class="text-center d-flex flex-column"> <i class='bx bxs-badge-check'></i> <span class="text-center fs-1">You have been logged in <br/> Successfully</span> </div> */}
                </div>
            </div>
        </div>
    </div>

</div>
            <br/>


        </>
    )
}
export default Login;