import ReactElasticCarousel from 'react-elastic-carousel'
import ProductComponent from '../productsComponent/ProductComponent'
import Categoriescomponent from '../utilities/categoriesComponent/Categories'
import classes from './ShopComponent.module.css'
import React, { useEffect, useState } from 'react'
import { ViewCarousel, ViewCompact, ViewList } from '@mui/icons-material'
import { Slider } from '@mui/material'
import Carousel from 'react-material-ui-carousel'
// import { products } from '../../Assets/js/utilities'
import { NavLink, useSearchParams } from 'react-router-dom'
import useSilverHook from '../../useSilverHook'
import { filterProducts, getMaxPrice, getproducts } from '../Admin/AdminComponent/addProduct/ProductAdd'
import ProductComponentSmall from '../product/product'
import LoaderComponent from '../utilities/loadingComponent/LoaderComponent'

export function getCategories(products){
      let categories = []
      for (let index = 0; index < products.length; index++) {
            let update = true
            const element = products[index];
            for (let index2 = 0; index2 < categories.length; index2++) {
                  const element2 = categories[index2];
                  // console.log(element2)
                  // console.log(element)
                  if(element.category === element2){
                        update = false
                  }
            }
            if(update){
                  categories.push(element.category)
            }
      }
      return categories
}

export default function ShopComponent({ addToCard, products }) {
     
      // const url = "http://eccomerce.eternalcompanyltd.com/";
      const url = 'http://localhost/ecomerce/'
      const [cat, setCat] = useSearchParams()
      const [category,setcategory] = useState('')
      const [viewSmall,setViewsmall] = useState(false)
      function toogleView(){
            setViewsmall(!viewSmall)
      }
      
      const breakpoints = [
            { width: 1, itemsToShow: 1 },
            { width: 550, itemsToShow: 2 },
            { width: 768, itemsToShow: 2 },
            { width: 1200, itemsToShow: 3 },
      ]
      //main variables
      // const [products,setproducts] = useState([])
      const [dispproducts, setdispProducts] = useState([])
      const [maxval,setmaxval] = useState(0)
      const [requirements, setrequirements] = useState({ min: getMaxPrice(products), max: 100, value: 20, })
      //main variables

      useEffect(() => {
            console.clear()
            
            setmaxval(getMaxPrice(products))
            setrequirements({ min: getMaxPrice(products), max: 100, value: 20, })
            console.log(products)
            console.log(maxval)
            console.log(cat.get("catigory"))
            if (cat.get("catigory")) {
                  setcategory(cat.get("catigory"))

            }
      }, [products])

      return (
            <div className="main ">

                  <div className="section">
                        {/* <!-- container --> */}
                        <div className="container">
                              {/* <!-- row --> */}
                              <div className="row">
                                    {/* <!-- ASIDE --> */}
                                    <div id="aside" className="col-md-3">
                                          {/* <!-- aside Widget --> */}
                                          <div id="get_category">
                                          </div>
                                          {/* <!-- /aside Widget --> */}

                                          {/* <!-- aside Widget --> */}
                                          <div className="aside">
                                                <h3 className="aside-title">Prie</h3>
                                                <div className="price-filter">
                                                      {/* <div id="price-slider" className="noUi-target noUi-ltr noUi-horizontal">
                                                            <div className="noUi-base">
                                                                  <div className="noUi-origin" style={{ left: "0%" }}>
                                                                        <div className="noUi-handle noUi-handle-lower" data-handle="0" tabindex="0" role="slider" aria-orientation="horizontal" aria-valuemin="0.0" aria-valuemax="100.0" aria-valuenow="0.0" aria-valuetext="1.00" style={{ zIndex: 5 }}>
                                                                        </div></div>
                                                                  <div className="noUi-connect" style={{ left: "0%", right: "0%" }}></div>
                                                                  <div className="noUi-origin" style={{ left: "100%" }}>
                                                                        <div className="noUi-handle noUi-handle-upper" data-handle="1" tabindex="0" role="slider" aria-orientation="horizontal" aria-valuemin="0.0" aria-valuemax="100.0" aria-valuenow="100.0" aria-valuetext="999.00" style={{ zIndex: 4 }}>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div> */}
                                                      <div className='w-100 d-flex'>
                                                            <Slider  value={requirements.min} onChange={(e) => setrequirements({ ...requirements, min: e.target.value })} className='' />
                                                      </div>
                                                      <div className="input-number price-min  ">
                                                           Apartir <input id="price-min" type="number" value={(Math.floor((requirements.min/100)*maxval))} onChange={(e) => setrequirements({ ...requirements, min: ((e.target.value/maxval)*100) })} />fcfa
                                                      </div>
                                                      <div className="input-number price-max">
                                                            {/* <input id="price-max" type="number" value={requirements.max} onChange={(e)=>setrequirements({...requirements,max:e.target.value})}  /> */}
                                                      </div>
                                                </div>
                                                <button id="search_btn_price">Allee</button>
                                          </div>
                                          {/* <!-- /aside Widget --> */}

                                          {/* <!-- aside Widget --> */}
                                          <div id="get_brand">
                                          </div>
                                          {/* <!-- /aside Widget --> */}

                                          {/* <!-- aside Widget --> */}
                                          <div className="aside">
                                                <h3 className="aside-title">
                                                      Meilleures ventes</h3>
                                                <div id="get_product_home" style={{position:"relative"}} className=''>
                                                      {/* <!-- product widget --> */}

                                                      <ViewCarousel />
                                                      {products.length>2 &&
                                                      <Carousel
                                                            className='w-100 '
                                                            animation='slide'
                                                            autoPlay
                                                            sx={{ paddingBottom: "80px" }}
                                                      >
                                                             <ProductComponent  addToCard={addToCard?addToCard:()=>{}} product={products[0]} />
                                                            <ProductComponent  addToCard={addToCard?addToCard:()=>{}} product={products[1]}/>
                                                            <ProductComponent  addToCard={addToCard?addToCard:()=>{}} product={products[2]}/> 

                                                      </Carousel>
}
                                                            <LoaderComponent loading={(!(products[0])|(!products[2]))}/>
                                                      {/* <!-- product widget --> */}
                                                </div>
                                          </div>
                                          {/* <!-- /aside Widget --> */}
                                    </div>
                                    {/* <!-- /ASIDE --> */}

                                    {/* <!-- STORE --> */}
                                    <div id="store" className="col-md-9">
                                          {/* <!-- store top filter --> */}
                                          <div className="store-filter clearfix">
                                                <div className="store-sort">
                                                      <label>
                                                            Trier par:
                                                            <select className="input-select">
                                                                  <option value="0">Populaire</option>
                                                                  <option value="1">Position</option>
                                                            </select>
                                                      </label>

                                                      <label>

                                                            Montrer:
                                                            <select className="input-select">
                                                                  <option value="0">20</option>
                                                                  <option value="1">50</option>
                                                            </select>
                                                      </label>
                                                </div>
                                                <ul className="store-grid" onClick={toogleView}>
                                                      {viewSmall && <>
                                                      <li className=""><i className="fa fa-th"><ViewCompact /></i></li>
                                                      <li className='active'><NavLink href="#"><ViewList /></NavLink></li></>
                                                      }
                                                      {!viewSmall && <>
                                                      <li className="active"><i className="fa fa-th"><ViewCompact /></i></li>
                                                      <li><NavLink href="#"><ViewList /></NavLink></li>
                                                      </> }
                                                </ul>
                                          </div>
                                          {/* <!-- /store top filter --> */}

                                          {/* <!-- store products --> */}
                                          <div className="row" id="product-row" style={{position:"relative"}}>
                                                <div className="col-md-12 col-xs-12" id="product_msg">
                                                </div>
                                                {products?.length > 0 && filterProducts(getproducts(products, true, category),0,Math.floor((requirements.min/100)*maxval)).map((product) => 
                                                viewSmall?<ProductComponentSmall  addToCard={addToCard ? addToCard : () => { }} product={product} />:
                                                <ProductComponent addToCard={addToCard ? addToCard : () => { }} product={product} />
                                                )}
                                                <LoaderComponent loading={!products[0]}/>
                                                {/* <!-- product --> */}
                                                <div id="get_product">
                                                      {/* <!-- Here we get product jquery Ajax Request--> */}

                                                </div>

                                                {/* <!-- /product --> */}
                                          </div>
                                          {/* <!-- /store products --> */}

                                          {/* <!-- store bottom filter --> */}
                                          {/* <div className="store-filter clearfix">
                                                <span className="store-qty">Showing 10-100 products</span>
                                                <ul className="store-pagination" id="pageno">
                                                      <li ><NavLink className="active" href="#aside">1</NavLink></li>

                                                      <li><NavLink href="#"><i className="fa fa-angle-right"></i></NavLink></li>
                                                </ul>
                                          </div> */}
                                          {/* <!-- /store sbottom filter --> */}
                                    </div>
                                    {/* <!-- /STORE --> */}
                              </div>
                              {/* <!-- /row --> */}
                        </div>
                        {/* <!-- /container --> */}
                  </div>
            </div>
      )
}
