import { CircularProgress } from "@mui/material"
import classes from "./LoaderComponent.module.css"
import React, { useState, useEffect } from 'react'

export default function LoaderComponent ({ loading }) {
      const [isLoading, setIsLoading] = useState(false)
      
      useEffect(() => {
            setIsLoading(loading)


      }, [loading])

      return (<>{isLoading &&
            <div className={classes.loaderContainer}>
                  <CircularProgress />
            </div>
      }
      </>
      )
}
