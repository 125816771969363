import { Button } from "@mui/material"
import ProductComponentSmall from "../../../product/product"
import classes from "./editProductsComponent.module.css"
import React, { useEffect, useState } from 'react'
import { Delete, Edit } from "@mui/icons-material"
import LoaderComponent from "../../../utilities/loadingComponent/LoaderComponent"
import ProductAdd from "../addProduct/ProductAdd"
import db from '../../../../firebase'
import { collection, deleteDoc, doc } from "firebase/firestore"

async function deleteProduct(product){
      // async function handleNew(){
            // const docRef = doc(db,"eccomerce","id123")
            // const payload = {name: "new name",value:"nvalue"}
            // await setDoc(docRef,payload)
                  
                  const docRef = doc(db,"eccomerce",product.id)
                  await deleteDoc(docRef)
                        alert("deleted succesfully")
                        return
                  
                  
       
            // const collectionRef = collection(db,"eccomerce")
            // await addDoc(collectionRef,form)
            // alert("product added")
            // console.log(form)
            // console.log("product added")
            // addDoc()
          
      //     }
}
export default function EditProductsComponent({ products }) {
      const [selected, setselected] = useState()
      const ProductEdit = function ({ product }) {
            return (
                  <>
                        <div key={product?.id} className="d-flex">
                              <ProductComponentSmall product={product}/>
                              <div className="d-flex flex-column align-items-center" style={{color:"red"}}>
                              <Button onClick={()=>setEdit(product)}>Modifier<Edit className='mx-2'/></Button>
                              <Button onClick={()=>deleteProduct(product)} style={{color:"red"}}>
                                  supprimer<Delete className='mx-2 btn-danger ' /></Button>
                              </div>

                        </div>
                  </>
            )
      }
      function setEdit(prod){
            // console.clear()
            // console.log(prod)
            window.scrollTo(0,0)
            setselected(prod)
      }
      
      useEffect(()=>{
            console.log("helllo",selected)
            console.log("name",selected?.title)
      },[selected])
      return (
            <div className={classes.container}>
                  <div>
                        {selected && <div><ProductAdd productEdit={selected}/></div>}
                  </div>
                  <div className="row"><div className="col">
                        {products && products[0] && products.map((product) => <ProductEdit product={product} />)}
                  </div></div>
            <LoaderComponent loading={!(products && products[0])}/>
            </div>
      )
}
