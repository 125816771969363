import styles from "./style.module.css"
import './style.css'
import logo from "../../Assets/logo/logo.jpg"
import { AccountCircle, AppRegistrationRounded, CurrencyFranc, Email, FavoriteBorder, LocationCityOutlined, Login, MapsHomeWorkSharp, Menu, MenuOpen, QuestionMark, ShoppingCart, WhatsApp } from "@mui/icons-material"
import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { NavLink } from "react-router-dom";
import { Badge, MenuList } from "@mui/material";
import { getCategories } from "../ShopComponent/ShopComponent";

export default function Header({ toggleCartDrawerx, cardtotal, setcardtotal,products }) {

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  // --- mobile dropdown list items
  const ListItemS = ({ textP }) => {
    return (
      <div key={textP} text={textP}>
        <Divider className={styles.divider} />
        <ListItem disablePadding>
          <ListItemButton>

            <ListItemText primary={textP} />
          </ListItemButton>
        </ListItem>
      </div>
    )
  }

  const lists = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 270 }}
      className={styles.dropdownContainer}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      key={anchor}
    >
      <Divider />
      <List>

        {/* ------ mobile dropdown */}
        <NavLink to={"/"}><ListItemS textP={"Home"} /></NavLink>
        <NavLink to={"/shop"}><ListItemS textP={"Shop"} /></NavLink>
        <NavLink to={"/contact"}> <ListItemS textP={"Contact"} /> </NavLink>
        <NavLink to={"/contact"}> <ListItemS textP={"Contact"} /> </NavLink>


        <Divider className={styles.divider} />
      </List>
    </Box>
  );

  const SideBar = () => {
    return (
      <div>
        {['left', 'right', 'top', 'bottom'].map((anchor) => (
          <React.Fragment key={anchor}>
            {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {lists(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </div>
    )
  }


  return (
    <>

      <header>
        {/* <!-- TOP HEADER --> */}
        <div id="top-header">
          <div className="container d-flex align-items-center ">
            <ul className="header-links pull-left ">
              <li><a href="tel:+16145694205"><i className="fa fa-whatsapp"><WhatsApp /></i> (+237)691-511-839</a></li>
              <li><a href="mailto: psychedel2official@gmail.com"><i className="fa fa-envelope-o"><Email /></i>flo@gmail.com</a></li>
              <li><a href="./"><i className="fa fa-map-marker"><LocationCityOutlined /></i>Power-herbal, CAM</a></li>
              {/* <!-- &#128293&#128293 --> */}
            </ul>
            <ul className="header-links pull-right ms-auto">
              <li><a href="#"><i className="fa fa-usd"><CurrencyFranc /></i>xcfa</a></li>
              <li>
                <div className="dropdownn">
                  <a href="#" className="dropdownn" data-toggle="modal" data-target="#myModal" ><i className="fa fa-user-o"><AccountCircle /></i> Mon Compte</a>
                  <div className="dropdownn-content" style={{ zIndex: "2" }}>
                    <a href="/login" data-toggle="modal" data-target="#Modal_login"><i className="fa fa-sign-in" aria-hidden="true" ><Login /></i>Se Connecter </a>
                    {/* <a href="" data-toggle="modal" data-target="#Modal_register"><i className="fa fa-user-plus" aria-hidden="true"><AppRegistrationRounded /></i>Sign Up</a> */}

                  </div>
                </div>

              </li>
            </ul>

          </div>
        </div>
        {/* <!-- /TOP HEADER --> */}



        {/* <!-- MAIN HEADER --> */}
        <div id="header">
          {/* <!-- container --> */}
          <div className="container">
            {/* <!-- row --> */}
            <div className="row">
              {/* <!-- LOGO --> */}
              <div className="col-md-3">
                <div className="header-logo ">
                  <a href="/" className="logo">
                    <font style={{ fontStyle: "normal", fontSize: '33px', color: 'black', fontFamily: 'serif' }}>
                      MAN POWER HERBAL
                    </font>

                  </a>
                </div>
              </div>
              {/* <!-- /LOGO --> */}

              {/* <!-- SEARCH BAR --> */}
              <div className="col-md-6">
                <div className="header-search">
                  <select onSelect={(e)=>{window.location.href = "/shop?catigory="+e}} className="input-select">
                    {products && products[0] && getCategories(products).map((category)=>
                  <option style={{color:"rgb(200,200,250)"}} key={category} className="categoryhome"><NavLink to={"/shop?catigory="+category}><a>{category}</a></NavLink></option>
            
            )}
                    {/* {getCategories(products).map((catego)=><NavLink to={'/shop?catigo='+catego}><option value={catego}>{catego}</option></NavLink>)} */}
                  </select>
                  <input className="input" id="search" type="text" placeholder="Search here" />
                  <button id="search_btn" className="search-btn">Search</button>
                </div>
              </div>
              {/* <!-- /SEARCH BAR --> */}

              {/* <!-- ACCOUNT --> */}
              <div className="col-md-3 clearfix">
                <div className="header-ctn">
                  {/* <!-- Wishlist --> */}
                  <div>
                    <a onclick="help()" style={{ cursor: 'pointer', color: "white", fontWeight: "bold" }}>
                      <i className="fa fa-question"><QuestionMark /></i>
                      <span><s>Aide</s></span>
                    </a>
                  </div>
                  {/* <!-- /Wishlist --> */}

                  {/* <!-- Cart --> */}
                  <div className="dropdown" style={{ cursor: "pointer",color:"black" }}>
                    <a className=" " onClick={toggleCartDrawerx} >
                      <i className="fa fa-shopping-cart"><Badge badgeContent={cardtotal} color={"error"}><ShoppingCart style={{color:"black"}} /></Badge></i>

                      <span className="">Votre Panier</span>
                      {/* <div className="badge qty">0</div> */}
                    </a>
                    <div className="cart-dropdown"  >
                      <div className="cart-list" id="cart_product">


                      </div>

                      <div className="cart-btns">
                        <a href="cart" style={{ width: '100%' }}><i className="fa fa-edit"></i>Modifier le Panier</a>

                      </div>
                    </div>

                  </div>
                  {/* <!-- /Cart --> */}

                  {/* <!-- Menu Toogle --> */}
                  <div className="menu-toggle">
                    <a href="#">
                      <i className="fa fa-bars"><Menu /></i>
                      <span>Menu</span>
                    </a>
                  </div>
                  {/* <!-- /Menu Toogle --> */}
                </div>
              </div>
              {/* <!-- /ACCOUNT --> */}
            </div>
            {/* <!-- row --> */}
          </div>
          {/* <!-- container --> */}
        </div>
        {/* <!-- /MAIN HEADER --> */}
      </header>


      {/* <!-- /HEADER --> */}
      <nav id='navigation'>
        {/* <!-- container --> */}
        <div className="container" id="get_category_home"  >
          <ul className="main-nav nav my-2 w-100 d-flex align-items-center"  style={{color:"white !important" }} >
            <li className="categoryhome"><NavLink  className={styles.menu} to={"/"}><a>Home</a></NavLink></li>

            <li className="categoryhome"><NavLink  className={styles.menu} to={"/shop"}><a>Boutique</a></NavLink></li>
            {products && products[0] && getCategories(products).map((category)=>
            <li style={{color:"rgb(200,200,250)"}} key={category} className="categoryhome"><a href={"/shop?catigory="+category}><a className={styles.menu}>{category}</a></a></li>
            
            )}
            






            {/* <li class="categoryhome" cid="2"><a href="store">Resistance</a></li> */}




            {/* <li class="categoryhome" cid="3"><a href="store">Enlargment</a></li> */}

          </ul>

        </div>


        {/* <!-- /container --> */}
      </nav>


      {/* <!-- NAVIGATION --> */}

      <div className="modal fade" id="Modal_login" role="dialog">
        <div className="modal-dialog">

          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">&times;</button>

            </div>
            <div className="modal-body">
              INCLUDE LOGIN FORM HERE
              {/* /  LOGIN COMPONENT  */}
              {/* <?php
                              include "login_form.php";
    
                            ?> */}

            </div>

          </div>

        </div>
      </div>


      <div className="modal fade" id="Modal_register" role="dialog">
        <div className="modal-dialog">

          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">&times;</button>

            </div>
            <div className="modal-body">
              INCLURE LE FORMULAIRE D'INSCRIPTION ICI
              {/* // REGISTER COMPONENT  */}
              {/* <?php
                              include "register_form.php";
    
                            ?> */}

            </div>

          </div>

        </div>
      </div>



      <div id="vid1" style={{ position: 'fixed', bottom: 0, right: 0, width: '350px', display: 'none', backgroundColor: '#C9D6FF', zIndex: 999 }}>
        <div style={{ position: 'relative', top: 0, color: 'red', width: '100%', height: '25px', display: 'block', backgroundColor: 'grey' }}><button className="close" title="close" onclick="cancell()" style={{ position: 'relative', right: 0 }}>X</button></div>


        {/* <?php
		?> */}
        <div className="card">
          <div className="card-body messages-box">
            <ul className="list-unstyled messages-list">
              {/* <?php
                             if(isset($_SESSION["uid"])){
                                $user_id = $_SESSION["uid"];
                              $res=mysqli_query($con,"SELECT * FROM message WHERE (tto=$user_id OR type=$user_id) ORDER BY added_on ASC");
							              if(mysqli_num_rows($res)>0){
                                $html = '';
                              while($row=mysqli_fetch_assoc($res)){
                                $message = $row['message'];
                              $added_on=$row['added_on'];
                              $strtotime=strtotime($added_on);
                              $time=date('h:i A',$strtotime);
                              $type=$row['type'];
                              if($type==0){
                                $class = "messages-you";
                                $imgAvatar="bot_avatar.png";
                                $name="Chatbot";
									            } 
                            else{
                                $class = "messages-me";
                              $imgAvatar="user_avatar.png";
                              $name="Me";
										
									}
                              $html=$html.'<li className="'.$class.' clearfix"><span className="message-img"><img src="chatbot/image/'.$imgAvatar.'" className="avatar-sm rounded-circle"></span><div className="message-body clearfix"><div className="message-header"><strong className="messages-title">'.$name.'</strong> <small className="time-messages text-muted"><span className="fas fa-time"><b></span> <span className="minutes">'.$time.'</span></b></small> </div><p className="messages-p">'.$message.'</p></div></li>';
								}
                            echo $html;
							}else{
								?>
								<li className="messages-me clearfix start_chat">
								   Please start
								</li>
								<? php
							}}
							?> */}
              <li className="messages-me clearfix start_chat">
                Please start
              </li>
            </ul>
          </div>
          <div className="card-header">
            <div className="input-group">
              <input id="input-me" type="text" name="messages" onblur="" className="form-control input-sm" placeholder="Tapez votre message ici..." />

              <span className="input-group-append">
                <input type="button" className="btn btn-primary" value="ENVOYEE" onclick="send_msg()" />
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* <script type="text/javascript">
                      var vid;

                      function cancell() {
                        document.getElementById('vid1').style.display = "none";
			  }
                      function help() {
                        document.getElementById('vid1').style.display = "block";
			  }

                      function getCurrentTime(){
			var now = new Date();
                      var hh = now.getHours();
                      var min = now.getMinutes();
			var ampm = (hh>=12)?'PM':'AM';
                      hh = hh%12;
                      hh = hh?hh:12;
                      hh = hh<10?'0'+hh:hh;
                      min = min<10?'0'+min:min;
                      var time = hh+":"+min+" "+ampm;
                      return time;
		 }
                      function send_msg(){
                        jQuery('.start_chat').hide();
                      var txt=jQuery('#input-me').val();
                      var html='<li className="messages-me clearfix"><span className="message-img"><img src="chatbot/image/user_avatar.png" className="avatar-sm rounded-circle"></span><div className="message-body clearfix"><div className="message-header"><strong className="messages-title">Me</strong> <small className="time-messages text-muted"><span className="fas fa-time"></span> <span className="minutes">'+getCurrentTime()+'</span></small> </div><p className="messages-p">'+txt+'</p></div></li>';





                      jQuery('.messages-list').append(html);
                      jQuery('#input-me').val('');
                      if(txt){
                        jQuery.ajax({
                          url: 'chatbot/get_bot_message.php',
                          type: 'post',
                          data: 'txt=' + txt,
                          success: function (result) {



                            var html = '<li className="messages-you clearfix"><span className="message-img"><img src="chatbot/image/bot_avatar.png" className="avatar-sm rounded-circle"></span><div className="message-body clearfix"><div className="message-header"><strong className="messages-title">Chatbot</strong> <small className="time-messages text-muted"><span className="fas fa-time"></span> <span className="minutes">' + getCurrentTime() + '</span></small> </div><p className="messages-p">' + result + '</p></div></li><a title="for each report, we upgrade a response" href="chatbot/invalidans.php" id="invalid_btn"><i>Invalid Answer ?</i></a>';

                            jQuery('.messages-list').append(html);
                            jQuery('.messages-box').scrollTop(jQuery('.messages-box')[0].scrollHeight);
                          }
                        });
			}
		 }
                    </script> */}

      {/* <?php
                    if (isset($_SESSION["uid"])) {
                      $user = $_SESSION["uid"];
    	}else{
                      $user = "Unknown";
    	}
                    $msg = $user." opened the gates at ".$_SESSION['page'];
    ?> */}

      <form style={{ display: 'none' }} id="formmmm" className="order-form" autocomplete="off">
        <button type="submit" id="buttonxx" style={{ backgroundColor: 'red' }}>j</button>
        <div className="field">
          <label for="from_name"></label>
          <input type="hidden" value="<?php echo $user; ?>" name="from_name" id="from_name" />
        </div>
        <div className="field">
          <label for="to_name"></label>
          <input type="hidden" name="to_name" value="GAS-HOUSE" id="to_name" />
        </div>
        <div className="field">
          <label for="message"></label>
          <input type="hidden" name="message" value="<?php echo $msg; ?>" id="message" />
        </div>

      </form>

      {/* <script>

                      function waitt() {

                      }

                      function doo() {
                        document.getElementById('buttonxx').click();
		}


                      emailjs.init('iDoswQGwoZNZ4-Kv9');
                      const btnn3 = document.getElementById('buttonxx');

                      document.getElementById('formmmm').addEventListener('submit', function(event) {
                        event.preventDefault();

                      btnn3.value = 'Sending...';

                      const serviceID = 'service_3wr6cuc';
                      const templateID = 'template_znpleo9';

                      emailjs.sendForm(serviceID, templateID, this)
    .then(() => {
                        waitt();
    }, (err) => {
                        waitt();
    });
});
                    </script> */}
      <SideBar />

    </>
  )
}