
import React, { useEffect, useState } from 'react'
import img from '../../Assets/img/WhatsApp Image 2023-10-30 at 13.58.52_8a29cbb3.jpg'
import BrandComponent from '../utilities/brandComponent/BrandComponent'
import ReactElasticCarousel from 'react-elastic-carousel'
import img1 from '../../Assets/img/WhatsApp Image 2023-10-30 at 13.58.51_823b6b35.jpg'
import img2 from '../../Assets/img/WhatsApp Image 2023-10-30 at 13.58.52_8a29cbb3.jpg'
import img3 from '../../Assets/img/WhatsApp Image 2023-10-30 at 13.58.52_db01e6d5.jpg'
import img4 from '../../Assets/img/WhatsApp Image 2023-10-30 at 13.58.53_cf2fef90.jpg'
import img5 from '../../Assets/img/WhatsApp Image 2023-10-30 at 13.58.55_10e1e7fc.jpg'
import img6 from '../../Assets/img/WhatsApp Image 2023-10-30 at 13.58.55_abf8e897.jpg'
import img7 from '../../Assets/img/WhatsApp Image 2023-10-30 at 13.58.57_508c2420.jpg'
import glow from '../../Assets/img/download.jpg'
import { ArrowRightAlt } from '@mui/icons-material'
import { Button } from '@mui/material'
import { NavLink, useSearchParams } from 'react-router-dom'
import Carousel from 'react-material-ui-carousel'
import ProductComponent from '../productsComponent/ProductComponent'
import { randBetween,breakpoints } from '../../Assets/js/utilities'
import ProductComponentSmall from '../product/product'
import LoaderComponent from '../utilities/loadingComponent/LoaderComponent'

export function fragmentArray(products,amount){
      let product = []
      for (let index = 0; index < products.length; index++) {
            let i = index
            let loop = amount
            let accum = []
            const element = product[index];
            while (loop>0) {
                  if(i<products.length){
                        // alert(index2)
                  const element2 = products[i];
                  // console.log(i)
                  // console.log("dddddddddddddddddddddddddddd")
                  accum.push(element2)
                  }
                  i++
                  loop--
                  
            }
            product.push(accum)
            index += amount-1
            
      }
      console.log(product)
      return product
}
export default function Home({addToCard,products}) {


      // const url = "http://eccomerce.eternalcompanyltd.com/";
      const url = 'http://localhost/ecomerce/'
      const [cat,setCat] = useSearchParams() 
      const breakpoints = [
            { width: 1, itemsToShow: 1 },
            { width: 550, itemsToShow: 2 },
            { width: 768, itemsToShow: 2 },
            { width: 1200, itemsToShow: 3 },
      ]
      //main variables
      // const [products,setproducts] = useState([])
      const [dispproducts,setdispProducts] = useState([])
      const [requirements,setrequirements] = useState({min:0,max:100,value:20,})
      //main variables

     
      







     
      const bannar=[
            {title:"offer",src:img2},
            {title:"offer",src:img2},
            {title:"offer",src:img2},
      ]
     
      const allbrands = [
            { src: img6, headtext: "Up to 20% off", remark: "Offered by the best" },
            { src: img2, headtext: "15% off", remark: "On Bestsellers" },
            { src: img3, headtext: "Cheaper rates", remark: "Hand moisturizer" },
            { src: img4, headtext: "Up to 20% off", remark: "Offered by the best" },
            { src: img5, headtext: "Up to 20% off", remark: "Offered by the best" }
      ]

      const allcategories = [
            { category: "Produits électriques" },
            // { category: "Rubbing oil" },
            // { category: "Perfumes" }, 
           
      ]
      useEffect(() => {
         
            window.scrollTo(0, 0)
      }, [window.location.to,products])
  

      function TopProducts ({element}){return (
            <div className="col-md-4 col-xs-6">
                  <div className="section-title">
                        <h4 className="title">Meilleures ventes</h4>
                        <div className="section-nav">
                              <div id="slick-nav-4" className="products-slick-nav"></div>
                        </div>
                  </div>

                  <div className="products-widget-slick" data-nav="#slick-nav-4">


                        <ReactElasticCarousel
                        showArrows={false}
                        autoPlaySpeed={(randBetween(6,12)*1000)}
                        enableAutoPlay={true}
                        pagination={false}
                        
                        >
                              {
                                    element.map((element)=>
                               <div id="get_product_home3">
                                    {
                                          <ProductComponentSmall product={element} />
                                    }
                              </div>)
                              }
                             
                        </ReactElasticCarousel>
                        {/* </ReactElasticCarousel> */}
                        {/* <!-- /product widgets --> */}

                        
                  </div>
            </div>
            )}
      return (
                   <>
                  <div id="get_product" style={{ display: "none" }}></div>
                  <div className="main mx-1">
                        <div className="container " style={{ width: "100%",position:"relative" }}>

                              <div id="myCarousel" style={{position:"absolute",top:"0px"}} className="carousel mx-auto slide">
                                    {/* <!-- Indicators --> */}


                                    {/* <!-- Wrapper for slides --> */}
                                    <div className="carousel-inner my-3 mx-auto border w-100">
                                    
                                          <Carousel className='mx-auto'>

                                                

                                               {bannar.map((brand)=> 
                                               <div className="item border mx-auto w-100">
                                                      <NavLink to={"/shop?brand="+brand.title} >

                                                            {/* <img src="img/banner2.jpg" style={{width:"100%"}}/> */}
                                                            <img src={brand.src} alt="first display" style={{ width: "100%" }} />
                                                      </NavLink>

                                                </div>)}

                                               
                                                
                                          </Carousel>
                                          {/* </ReactElasticCarousel> */}

                                    </div>

                                    {/* <!-- Left and right controls --> */}
                                    
                                    <NavLink className="left carousel-control _26sdfg" to="#myCarousel" data-slide="prev">

                                    </NavLink>
                                    <NavLink className="right carousel-control _26sdfg" to="#myCarousel" data-slide="next">

                                    </NavLink>
                              </div>
                        </div>



                        {/* <!-- SECTION --> */}
                        <div className="section mainn mainn-raised">


                              {/* <!-- container --> */}
                              <div className="container">

                                    {/* <!-- row --> */}
                                    <div className="row">
                                          {/* <!-- shop --> */}
                                          {allcategories.map((cat)=> 
                                          <div className="col-md-4 mx-auto  col-xs-6">
                                                <NavLink to={"/shop?brand="+cat.category}><div className="shop">

                                                      <div className="shop-img">

                                                            {/* <img src="./product_images/1655062860_Unflavored.webp" alt=""/> */}
                                                            <img src={img1} alt={"product"} />
                                                      </div>
                                                      <div className="shop-body">
                                                            <h3>{cat.category}<br />Collections</h3>
                                                            <NavLink to={"/shop?brand="+cat.category} className="cta-btn btn border">Achetez maintenant <i className="fa fa-arrow-circle-right"><ArrowRightAlt /></i></NavLink>

                                                      </div>
                                                </div></NavLink>
                                          </div>)}
                                         
                                          {/* <!-- /shop --> */}
                                    </div>
                                    {/* <!-- /row --> */}
                              </div>
                              {/* <!-- /container --> */}
                        </div>
                        {/* <!-- /SECTION --> */}



                        {/* <!-- SECTION --> */}
                        <div className="section">
                              {/* <!-- container --> */}
                              <div className="container">
                                    {/* <!-- row --> */}
                                    <div className="row">

                                          {/* <!-- section title --> */}
                                          <div className="col-md-12">
                                                <div className="section-title">
                                                      <h3 className="title">Nouveaux produits</h3>
                                                      <div className="section-nav">
                                                            <ul className="section-tab-nav tab-nav">
                                                                  <li className="active"><NavLink data-toggle="tab" to="#tab1">Tous</NavLink></li>
                                                            </ul>
                                                      </div>
                                                </div>
                                          </div>
                                          {/* <!-- /section title --> */}

                                          {/* <!-- Products tab & slick --> */}
                                          <div className="col-md-12 mainn mainn-raised">
                                                <div className="row">
                                                      <div className="products-tabs">
                                                            {/* <!-- tab --> */}
                                                            <div id="tab1" className="tab-pane active">
                                                                  <div className="" data-nav="#slick-nav-1" style={{position:'relative'}}>

                                                           
                                                                        <ReactElasticCarousel enableAutoPlay autoPlaySpeed={3500} className='py-4' breakPoints={breakpoints} pagination={false}>
                                                                              {products.length>0 && products.map((product)=><ProductComponent  addToCard={addToCard} product={product} />)}

                                                                              
                                                                        </ReactElasticCarousel>
                                                                        <LoaderComponent loading={!products[0]}/>

                                                                       
                                                                  </div>
                                                                  <div id="slick-nav-1" className="products-slick-nav"></div>
                                                            </div>
                                                            {/* //     <!-- /tab --> */}
                                                      </div>
                                                </div>
                                          </div>
                                          {/* //     <!-- Products tab & slick --> */}
                                    </div>
                                    {/* //     <!-- /row --> */}
                              </div>
                              {/* //     <!-- /container --> */}
                        </div>
                        {/* //     <!-- /SECTION --> */}

                        {/* //     <!-- HOT DEAL SECTION --> */}
                        <div id="hot-deal" className="section mainn mainn-raised">
                              {/* //     <!-- container --> */}
                              <div className="container">
                                    {/* //     <!-- row --> */}
                                    <div className="row">
                                          <div className="col-md-12">
                                                <div className="hot-deal">
                                                      <ul className="hot-deal-countdown">
                                                            <p id="demo" style={{ fontSize: "60px", marginTop: "0px" }}></p>
                                                            <li>
                                                                  <div>
                                                                        <h3 id="dd">00</h3>
                                                                        <span>Days</span>
                                                                  </div>
                                                            </li>
                                                            <li>
                                                                  <div>
                                                                        <h3 id="hh">00</h3>
                                                                        <span>Heur</span>
                                                                  </div>
                                                            </li>
                                                            <li>
                                                                  <div>
                                                                        <h3 id="mm">00</h3>
                                                                        <span>Minute</span>
                                                                  </div>
                                                            </li>
                                                            <li>
                                                                  <div>
                                                                        <h3 id="ss">00</h3>
                                                                        <span>Secds</span>
                                                                  </div>
                                                            </li>
                                                      </ul>
                                                      <h2 className="text-uppercase">bonne affaire ce mois-ci</h2>
                                                      <p>Nouvelle collection jusqu'à 25% de réduction</p>
                                                      <NavLink to={"/shop"} className="primary-btn cta-btn">Achetez maintenant</NavLink>
                                                </div>
                                          </div>
                                    </div>
                                    {/* //     <!-- /row --> */}
                              </div>
                              {/* //     <!-- /container --> */}
                        </div>
                        {/* //     <!-- /HOT DEAL SECTION --> */}
                        {/* <script>
                              var countDownDate = new Date("Aug 30, 2022");
                              //Feb 15, 2012
                              //2012, 1, 15, 0, 0, 0, 0
                              countDownDate = Date.parse(countDownDate);
                              var now = new Date();
                              now = Date.parse(now);
                              // Update the count down every 1 second
                              var x = setInterval(function() {

                                    now = now + 1000;
                              var distance = countDownDate - now;

                              var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                              var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                              var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                              var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                              $("#dd").html(days);
                              $("#hh").html(hours);
                              $("#mm").html(minutes);
                              $("#ss").html(seconds);

                              if (distance < 0) {
                                    clearInterval(x);
                              document.getElementById("demo").innerHTML = "EXPIRED";
            }
        }, 1000);
                        </script> */}
<div className=" my-5  w-100 border">
                                                <div className="row my-4">
                                                      <div className="products-tabs">
                                                            {/* <!-- tab --> */}
                                                            <div id="tab1" className="tab-pane active">
                                                                  <div className=" row my-4" data-nav="#slick-nav-1" style={{position:'relative'}}>

                                                           
                                                                              {products.length>0 && products.map((product)=><div className='col my-2'><ProductComponent  addToCard={addToCard} product={product} /></div>)}

                                                                              
                                                                        <LoaderComponent loading={!products[0]}/>

                                                                       
                                                                  </div>
                                                                  <div id="slick-nav-1" className="products-slick-nav"></div>
                                                            </div>
                                                            {/* //     <!-- /tab --> */}
                                                      </div>
                                                </div>
                                          </div>
                        {/* <!-- SECTION --> */}
                        {/* //     <!-- /SECTION --> */}

                        {/* //     <!-- SECTION --> */}
                        <div className="section">
                              {/* //     <!-- container --> */}
                              <div className="container">
                                    {/* //     <!-- row --> */}
                                    <div className="row">
                                          {
                                                fragmentArray(products,4).map((element)=>
                                                      <TopProducts element={element}/>
                                                )
                                          }
                                                      {/* <TopProducts/> */}

                                          <div className="clearfix visible-sm visible-xs">

                                          </div>

                                       

                                    </div>
                                    {/* <!-- /row --> */}
                              </div>
                              {/* <!-- /container --> */}
                        </div>
                        {/* <!-- /SECTION --> */}
                  </div>

            </>)
}
