import classes from './ProductComponent.module.css'
import React from 'react'
import img2 from '../../Assets/img/WhatsApp Image 2023-10-30 at 13.58.52_8a29cbb3.jpg'
import img3 from '../../Assets/img/WhatsApp Image 2023-10-30 at 13.58.52_db01e6d5.jpg'
import { Rating } from '@mui/material'
import { Compare, CompareArrows, CurrencyExchange, DoubleArrowSharp, FavoriteBorder, PanoramaFishEye, ShoppingCart, ViewAgenda, Visibility } from '@mui/icons-material'
import AddToCard from './addtoCard/AddToCard'
import zIndex from '@mui/material/styles/zIndex'
// import { products } from '../../Assets/js/utilities'
import useCart from '../../Assets/hooks/useCart'
import { NavLink } from 'react-router-dom'
import ReactImageMagnify from 'react-image-magnify'


export default function ProductComponent({ product, addToCard }) {
      return (
            <>

                  <div className='product my-5 mx-auto' style={{ width: "255px", margin: '4px', maxWidth: "255px", height: "386px", position: "relative" }}>
                        <a href={'/preview/' + product?.id + '?p=' + product?.id}><div className='product-img'>

                              <img src={product?.image1 ? product.image1 : img3} style={{ height: "190px" }} alt='' />
                              <div className='product-label'>
                                    {/* <span className='sale'>{product?.newTag}%</span> */}
                                    <span className='new'>NEW</span>
                              </div>

                        </div>
                        </a>
                        <div className='product-body'>
                              <p className='product-category'>{product?.category}</p>
                              <h3 className='product-name header-cart-item-name'><a href={'/preview/' + product?.id + '?p=' + product?.id}>{product?.title}</a></h3>
                              <h4 className='product-price header-cart-item-info'>{product?.newPrice}fcfa{product?.oldPrice > 0 && <del className='product-old-price'><del>{product?.oldPrice}fcfa</del></del>}</h4>
                              <div className='product-rating d-flex align-items-center row-reverse'>
                                    <Rating sx={{ color: "red", zIndex: 1, backgroundColor: "white" }} readOnly={true} value={product?.rating} />

                              </div>
                              <div className='product-btns'>
                                    <button className='add-to-wishlist'><FavoriteBorder /><span className='tooltipp'>
                                          ajouter à la liste de souhaits</span></button>
                                    <button className='add-to-compare'><CompareArrows /><span className='tooltipp'>ajouter pour comparer</span></button>
                                    <button className='quick-view'><Visibility /><span className='tooltipp'>aperçu rapide</span></button>
                              </div>
                        </div>
                        <div className='add-to-cart ' onClick={() => addToCard(product ? product : "") ? addToCard(product) : () => { }} >
                              <button pid='2' id='product' style={{ fontSize: ".9em !important" }} className='add-to-cart-btn block2-btn-towishlist' to='/#'><ShoppingCart />
                                    Ajouter au panier</button>
                        </div>
                  </div>

            </>
      )
}
