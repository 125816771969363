import ProductComponentSmall from "../product/product";
import "./checkout.css";

import * as React from "react";

export const CheckOut = ({totalPrice, shopingBag, products, cardtotal }) => {

    React.useEffect(() => {

        window.scrollTo(0, 0)

        return () => {

        }
    }, [])
    return (
        <div style={{ backgroundColor: "black", color: "whitesmoke", paddingBottom: "20px", paddingTop: "20px" }}>
            <div className={"container"} style={{ textAlign: "center" }}>
                <div >
                    <p>
                        Déjà client? <span className="a-link">Cliquez ici pour être redirigé vers WhatsApp</span>
                    </p>
                    {/* <p>
                        Have a coupon? <span className="a-link">Click here to enter your code</span>
                    </p> */}
                </div>

                <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "50px" }} className={"checkout"}>
                    {/* <form  className="form-field">
                        <div style={{display:"flex",gap:"30px"}}>
                            <div style={{gap:"10px",textAlign:"start",width:"100%"}} >
                                <label>First name *</label>
                                <input type={"text"} className={"form-control"} placeholder={"enter firstname"}/>
                            </div>
                            <div style={{gap:"10px",textAlign:"start",width:"100%"}}>
                                <label>Last name *</label>
                                <input className={"form-control"} type={"text"}  placeholder={"enter firstname"}/>
                            </div>
                        </div>
                        <div style={{gap:"20px",textAlign:"start",width:"100%"}}>
                        <label style={{gap:"10px",textAlign:"start"}} >Company name (optional)</label>
                        <input className={"form-control"} placeholder="Company name"/>
                            <label style={{gap:"10px",textAlign:"start"}} >Country / Region *</label>
                            <input className={"form-control"} placeholder="Country / Region"/>
                            <label style={{gap:"10px",textAlign:"start"}} >Street address *</label>
                            <input className={"form-control"} placeholder="Street address"/>
                            <input className={"form-control"}/>
                            <label style={{gap:"10px",textAlign:"start"}} >Town / City *</label>
                            <input className={"form-control"} placeholder="Town / City"/>
                            <label style={{gap:"10px",textAlign:"start"}} >State *</label>
                            <input className={"form-control"} placeholder="State"/>
                            <label style={{gap:"10px",textAlign:"start"}} >ZIP Code *</label>
                            <input className={"form-control"} placeholder="ZIP Code"/>
                            <label style={{gap:"10px",textAlign:"start"}} >Phone *</label>
                            <input className={"form-control"} placeholder="Phone"/>
                            <label style={{gap:"10px",textAlign:"start"}} >Email address *</label>
                            <input className={"form-control"} placeholder="Email address"/>
                            <div style={{display:"flex",gap:"10px"}}>
                                <input type="checkbox"/>
                                <p style={{marginTop:"4px",color:"grey"}}>create an account?</p>
                            </div>
                            <h1>Additional Information</h1>
                            <p>Order Notes (Optional)</p>
                            <div className="form-group">
                            <textarea name="message" className="form-control" id="message" cols="30" rows="8" placeholder="Message" style={{backgroundColor:"transparent",color:"white"}}></textarea>
                            </div>

                        </div>
                    </form> */}
                    <div style={{ backgroundColor: "lightgrey", borderRadius: "10px" }} className={"sec2"}>
                        <div style={{ display: "flex", justifyContent: "space-between", padding: "20px", color: "black" }}>
                            <h6>produit</h6>
                            <div className="row">
                            {shopingBag.map((product) =>
                        
                                <div key={product?.id} className="col"><ProductComponentSmall product={product} /></div>)}
                            
                             </div>
                             <h6>subtotal</h6>


                        </div>
                        <div style={{ padding: "0px 20px 0px 20px" }}><hr style={{ color: "black" }} /></div>
                        <div style={{ display: "flex", justifyContent: "space-between", padding: "20px", color: "black" }}>
                            <h6>Nouvelle politique x3</h6>
                            <h6>CFA 0.00</h6>


                        </div>
                        <div style={{ padding: "0px 20px 0px 20px" }}><hr style={{ color: "black" }} /></div>
                        <div style={{ display: "flex", justifyContent: "space-between", padding: "20px", color: "black" }}>
                            <h6>Total</h6>
                            <h6>CFA 0.00</h6>


                        </div>
                        <div style={{ padding: "0px 20px 0px 20px" }}><hr style={{ color: "black" }} /></div>
                        <div style={{ display: "flex", justifyContent: "space-between", padding: "20px", color: "black" }}>
                            <h6>Total</h6>
                            <h4>{totalPrice} fcfa</h4>


                        </div>
                        <div style={{ padding: "0px 20px 0px 20px" }}><hr style={{ color: "black" }} /></div>
                        <div style={{ textAlign: "start", padding: "10px" }}>
                            <p style={{ color: "black" }}>
                                Vos données personnelles seront utilisées pour traiter votre commande, pour améliorer votre expérience sur ce site Web et à d'autres fins décrites dans notre politique de confidentialité</p>
                        </div>
                        <div style={{ padding: "0px 20px 10px 20px" }}>
                            <button className="btn card-btn" style={{ marginBottom: "10px", borderRadius: "10px", backgroundColor: "#e1e1e1", width: "100%", padding: "10px" }}>Passer la commande</button>
                        </div>







                    </div>
                </div>

            </div>

        </div>

    )
}