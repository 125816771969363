import ShopComponent from '../../../ShopComponent/ShopComponent'
import classes from './displayProductsComponent.module.css'

import React from 'react'

export default function DisplayProductsComponent({products,setproducts,catego}) {
      return (
            <div className='my-5'>
                  <h3 className='my-2'><center>Shopping</center></h3>
                  <h4>Categorie: {catego}</h4>
                  <ShopComponent catego={catego}  products={products} setproducts={setproducts}/>
            </div>
      )
}
