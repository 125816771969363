// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCZr1MsicdgJWOAbZquxHC2yLDQhRiWHdw",
  authDomain: "flo-store-e5a88.firebaseapp.com",
  projectId: "flo-store-e5a88",
  storageBucket: "flo-store-e5a88.appspot.com",
  messagingSenderId: "836327243661",
  appId: "1:836327243661:web:e760634747a5b49d3347c4",
  measurementId: "G-74JPFSYKK6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default getFirestore()