export     const products=[
      {title:"Shampoo",category:"skin tone",oldPrice: 1000,newPrice:500,liked:2,rating:randBetween(1,6),newTag:percentDifference(500,1000)},
      {title:"Shampoo",category:"rubbing oil",oldPrice: 1000,newPrice:500,liked:2,rating:randBetween(1,6),newTag:percentDifference(500,1000)},
      {title:"Rub",category:"perfume",oldPrice: 2500,newPrice:500,liked:2,rating:randBetween(1,6),newTag:percentDifference(500,2500)},
      {title:"Shampoo",category:"skin tone",oldPrice: 7000,newPrice:500,liked:2,rating:randBetween(1,6),newTag:percentDifference(7000,500)},
      {title:"Shampoo",category:"skin tone",oldPrice: 1000,newPrice:500,liked:2,rating:randBetween(1,6),newTag:percentDifference(500,1000)},
      {title:"Shoe",category:"skin tone",oldPrice: 37500,newPrice:500,liked:2,rating:1,newTag:percentDifference(500,1000)},
      {title:"Shampoo",category:"perfume",oldPrice: 1000,newPrice:500,liked:2,rating:5,newTag:percentDifference(500,1000)},
      {title:"Spoon",category:"skin tone",oldPrice: 9900,newPrice:8500,liked:2,rating:randBetween(1,6),newTag:percentDifference(500,1000)},
      {title:"Shampoo",category:"perfume",oldPrice: 1000,newPrice:500,liked:2,rating:4,newTag:percentDifference(500,1000)},
      {title:"Cup",category:"skin tone",oldPrice: 1700,newPrice:1500,liked:2,rating:5,newTag:percentDifference(500,1000)},
      {title:"Shampoo",category:"skin tone",oldPrice: 15000,newPrice:10000,liked:2,rating:randBetween(1,6),newTag:percentDifference(15000,10000)},
]
/** percent difference between 
 * -new price and
 * -old price
 */
export function percentDifference(num1,num2){
      return Math.floor(((num1-num2)/num1)*100);
}
export    /** retuns a random number from 0 to num */
function rand(num){
      return Math.floor(Math.random()*num)
}
export function randBetween(num1,num2){
      let num = (rand(num2)-rand(num1))
      if(num < num1){
            return randBetween(num1,num2)
      }
      return num
}
export     const breakpoints = [
      { width: 1, itemsToShow: 1 },
      { width: 550, itemsToShow: 2 },
      { width: 768, itemsToShow: 3 },
      { width: 1200, itemsToShow: 4 },
]