
import LoaderComponent from '../utilities/loadingComponent/LoaderComponent'
// import classes from './Preview.module.css'
import React, { useEffect, useState } from 'react'
// import Slider from "react-slick";
// import 'slick-carousel/slick/slick.css'
// import 'slick-carousel/slick/slick-theme.css'
import { products } from '../../Assets/js/utilities'

import Carousel from 'react-material-ui-carousel'


// import SlideComponent from './slideComponent/SlideComponent';
import img1 from '../../Assets/img/WhatsApp Image 2023-10-30 at 13.58.51_823b6b35.jpg'
import img2 from '../../Assets/img/WhatsApp Image 2023-10-30 at 13.58.52_8a29cbb3.jpg'
import img3 from '../../Assets/img/WhatsApp Image 2023-10-30 at 13.58.52_db01e6d5.jpg'
import img4 from '../../Assets/img/WhatsApp Image 2023-10-30 at 13.58.53_cf2fef90.jpg'
import img5 from '../../Assets/img/WhatsApp Image 2023-10-30 at 13.58.55_10e1e7fc.jpg'
import img6 from '../../Assets/img/WhatsApp Image 2023-10-30 at 13.58.55_abf8e897.jpg'
import img7 from '../../Assets/img/WhatsApp Image 2023-10-30 at 13.58.57_508c2420.jpg'
import img8 from '../../Assets/img/WhatsApp Image 2023-10-30 at 13.58.57_a41dc235.jpg'
import img9 from '../../Assets/img/WhatsApp Image 2023-10-30 at 13.58.58_d1e9c877.jpg'
import img10 from '../../Assets/img/WhatsApp Image 2023-10-30 at 13.58.59_f30f345e.jpg'
import ProductComponent from '../productsComponent/ProductComponent'
import ReactElasticCarousel from 'react-elastic-carousel'
import { Email, Facebook, Google, ShoppingCart, Twitter } from '@mui/icons-material'
import { Rating } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import ReactImageMagnify from 'react-image-magnify'


export default function Preview({ addToCard, products }) {
      const url = "http://eccomerce.eternalcompanyltd.com/";
      // const url = 'http://localhost/ecomerce/'

      const [paramsVal, setparamsVal] = useSearchParams()
      const [submiting, setsubmiting] = useState(false)
      const [preview, setPreview] = useState(true)
      const [product, setproduct] = useState()




      const [isLoading, setIsLoading] = useState(true)

      const allslides = [
            { src: img1, description: "Surprenez votre cheri avec un cadeau spécial 1" },
            { src: img2, description: "Surprenez votre cheri avec un cadeau spécial 2" },
            { src: img3, description: "Surprenez votre cheri avec un cadeau spécial 3" },
            { src: img4, description: "Surprenez votre cheri avec un cadeau spécial 4" },
            { src: img5, description: "Surprenez votre cheri avec un cadeau spécial 5" },
            { src: img6, description: "Surprenez votre cheri avec un cadeau spécial 6" },
            { src: img7, description: "Surprenez votre cheri avec un cadeau spécial 7" },
            { src: img8, description: "Surprenez votre cheri avec un cadeau spécial 8" },
            { src: img9, description: "Surprenez votre cheri avec un cadeau spécial 9" },
            { src: img10, description: "Surprenez votre cheri avec un cadeau spécial 10" }
      ]
      useEffect(() => {
            // setparamsVal(paramsVal.get("p"))
            if (!paramsVal.get("p")) {
                  window.location.href = "/"
            }

            setsubmiting(true)
            console.log(new FormData())
            let formData = new FormData()
            formData.append("url", url)
            formData.append("endpoint", "allproducts")
            formData.append("product", paramsVal.get("p"))
            let data = new URLSearchParams(formData).toString()
            console.log(data)
            // setfileForm(formData)
            fetch(
                  url,
                  {
                        method: "post",
                        body: data,
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                  }
            ).then(response => response.json())
                  .then(
                        result => {
                              if (result.includes("success")) {
                                    // on success
                                    alert("succes");
                              }
                              // setproduct(result[0]?result[0]:undefined)
                              console.log(result)
                              // setfile('')
                              // setsubmiting(false)
                        }
                  ).catch(() => { console.log("error") })
            // console.log(form)
            for (let index = 0; index < products.length; index++) {
                  const element = products[index];
                  if (element.id == paramsVal.get("p")) {
                        setproduct(element)
                        console.log("element", element)
                        console.log("params", paramsVal.get("p"))
                  } else {
                  }

            }

            setTimeout(() => {

                  setIsLoading(false)
            }, 5000);

      }, [products, product])


      const breakpoints = [
            { width: 1, itemsToShow: 1 },
            { width: 550, itemsToShow: 2 },
            { width: 768, itemsToShow: 3 },
            { width: 1200, itemsToShow: 4 },
      ]
      const settings = {
            dots: true,
            fade: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 15000,
            cssEase: "linear"
      };

      return (
            <>
                  <LoaderComponent loading={isLoading} />
                  <div class="section main " style={{ position: "relative" }}>
                        {/* container */}
                        <div class="container">
                              {/* row */}
                              <div class="row ">
                                    {/* Product main img */}
                                    {/* <div class="col-md-2  col-md-pull-5 h-100  h-100  my-auto">
                                          <div id="product-imgs" className=''>
                                                <Carousel
                                                      itemsToShow={3}

                                                      style={{ width: "100%" }}
                                                      verticalMode

                                                >
                                                      <div class="product-preview">
                                                            <img src={product?.image1} alt="" />
                                                      </div>

                                                      {product?.image2 !== "" && <div class="product-preview">
                                                            <img src={product?.image2} alt="" />
                                                      </div>}

                                                      {product?.image3 !== "" && <div class="product-preview">
                                                            <img src={product?.image3} alt="" />
                                                      </div>}


                                                </Carousel>
                                          </div>
                                    </div> */}


                                    <div class="col-md-6 col-md-push-2  d-flex flex-column">
                                          <div id="product-main-img" className=''>

                                                <div class="product-preview my-3  border" style={{position:"relative"}}>
                                                       <ReactImageMagnify

                                                            {...{
                                                                  smallImage: {
                                                                        alt: 'Wristwatch by Ted Baker London',
                                                                        isFluidWidth: true,
                                                                        src: product?.image1
                                                                  },
                                                                  largeImage: {
                                                                        src: product?.image1,
                                                                        width: 1200,
                                                                        height: 1200
                                                                  },
                                                                  enlargedImagePosition: "over",
                                                            style:{ maxHeight: "485px", zIndex: 10,overflow:"hidden" }

                                                            }} />
                                                            <LoaderComponent loading={!product}/>
                                                      {/* <img src={product?.image1} alt="" className='zoomImg' /> */}
                                                      {/* <img role="presentation" src={product?.image1}class="zoomImg" style={{position: "absolute",opacity:0, top: "22.3932px", left: "125.819px", opacity: 1, width: "400px", height: "318px",border: "none", maxWidth: "none", maxHeight: "none"}}/> */}
                                                </div>


                                          </div>
                                          <div class="col-md-2  col-md-pull-5 h-100  h-100  my-auto">
                                                <div id="product-imgs" className=''>
                                                      <Carousel
                                                            itemsToShow={3}

                                                            style={{ width: "100%" }}


                                                      >
                                                            <div style={{ width: "70px", height: "70px" }}>
                                                                  <img src={product?.image1} alt="" style={{ width: "70px", height: "70px" }} />
                                                            </div>

                                                            {product?.image2 !== "" && <div style={{ width: "30px", height: "30px" }}>
                                                                  <img src={product?.image2} alt="" style={{ width: "70px", height: "70px" }} />
                                                            </div>}

                                                            {/* {product?.image3 !== "" && <div style={{width:"30px",height:"30px"}}>
                                                            <img src={product?.image3} style={{width:"70px",height:"70px"}} alt="" />
                                                      </div>} */}


                                                      </Carousel>
                                                </div>
                                          </div>
                                    </div>




                                    {/* FlexSlider */}

                                    <div class="col-md-5">
                                          <div class="product-details">
                                                <h2 class="product-name">{product?.title}</h2>
                                                <div>
                                                      <div class="product-rating">
                                                            {/* <Rating sx={{color:"red",zIndex:1,backgroundColor:"white"}} readOnly={true} value={3} /> */}
                                                            <Rating readOnly sx={{ color: "red" }} value={Number(product?.rating)} />
                                                      </div>
                                                      <a class="review-link" href="#review-form">2342 | Ajoutez votre avis </a>
                                                </div>
                                                <div>
                                                      <h3 class="product-price">{product?.newPrice} fcfa<del class="product-old-price">{product?.oldPrice ? product.oldPrice + " fcfa" : ""}</del></h3>
                                                      <span class="product-available">En stock</span>
                                                </div>
                                                <p>Brève Description</p>


                                                <div class="add-to-cart">
                                                      <div class="qty-label">
                                                            Qty
                                                            <div class="input-number">
                                                                  <input type="number" value="1" name="qtty" id="qtty" />

                                                                  <span class="qty-up">+</span>
                                                                  <span class="qty-down">-</span>
                                                            </div>
                                                      </div>
                                                      <div class="btn-group" style={{ marginLeft: "25px", marginTop: "15px" }}>
                                                            <button onClick={()=>{if(product){addToCard(product)}}} class="add-to-cart-btn" pid="'.$row['product_id'].'" id="product" data-toggle="modal" data-target="#Modal_login" name="rev"><i class="fa fa-shopping-cart"><ShoppingCart /></i> Ajouter au panier</button>

                                                      </div>


                                                </div>
                                                <div class="col-md-12 col-xs-12" id="product_msg"></div>


                                                <ul class="product-links">
                                                      <li>Catégorie:</li>
                                                      <li><a href={"/shop/?catigory=" + product?.category}>{product?.category}</a></li>
                                                </ul>

                                                <ul class="product-links">
                                                      <li>Share:</li>
                                                      <li><a href="#"><i class="fa fa-facebook"><Facebook /></i></a></li>
                                                      <li><a href="#"><i class="fa fa-twitter"><Twitter /></i></a></li>
                                                      <li><a href="#"><i class="fa fa-google-plus"><Google /></i></a></li>
                                                      <li><a href="#"><i class="fa fa-envelope"></i><Email /></a></li>
                                                </ul>

                                          </div>
                                    </div>


                                    {/* /Product main img */}

                                    {/* Product thumb imgs */}



                                    {/* /Product thumb imgs */}

                                    {/* Product details */}

                                    {/* /Product details */}

                                    {/* Product tab */}
                                    <div class="col-md-12">
                                          <div id="product-tab">
                                                {/* product tab nav */}
                                                <ul class="tab-nav">
                                                      <li class=""><a data-toggle="tab" href="#tab1">Description</a></li>
                                                      {/* <li><a data-toggle="tab" href="#tab2">Details</a></li> */}
                                                      <li><a data-toggle="tab" href="#tab3">Reviews </a></li>
                                                </ul>
                                                {/* /product tab nav */}

                                                {/* product tab content */}
                                                <div class="tab_pane">
                                                      {/* tab1  */}
                                                      <div id="tab1" class="">
                                                            <div class="row">
                                                                  <div class="col-md-12">
                                                                        <p dangerouslySetInnerHTML={{ __html: product?.longDesc }}></p>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                      {/* /tab1  */}

                                                      {/* tab2  */}
                                                      <div id="tab2" class="tab-pane ">
                                                            <div class="row">
                                                                  <div class="col-md-12">
                                                                        <p>
                                                                              <br />
                                                                        </p>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                      {/* /tab2  */}

                                                      {/* tab3  */}
                                                      <div id="tab3" class="tab-pane ">
                                                            <div class="row">
                                                                  {/* Rating */}
                                                                  <div class="col-md-3">


                                                                        <Rating />

                                                                  </div>
                                                                  {/* /Rating */}

                                                                  {/* Reviews */}
                                                                  <div class="col-md-6">
                                                                        reviews
                                                                        <div id="reviews">
                                                                              <h3>No reviews yet</h3>

                                                                        </div>
                                                                  </div>

                                                            </div>
                                                      </div>
                                                      {/* /Reviews */}

                                                      {/* Review Form */}
                                                      <div class="col-md-3 mainn">
                                                            <div id="review-form">
                                                                  <form class="review-form" method="POST">
                                                                        <input class="input" type="text" placeholder="Your Name" name="name" />
                                                                        <input type="hidden" value="'.$product_id.'" name="p" />
                                                                        <input class="input" type="email" placeholder="Your Email" name="emai" />
                                                                        <textarea class="input" placeholder="Your Review" name="review"></textarea>
                                                                        <div class="input-rating">
                                                                              <span>Your Rating: </span>
                                                                              <Rating sx={{ color: "red" }} />
                                                                              {/* /Review Form */}
                                                                        </div>
                                                                  </form>
                                                            </div>
                                                            {/* /tab3  */}
                                                      </div>
                                                      {/* /product tab content  */}
                                                </div>
                                          </div>
                                          {/* /product tab */}
                                    </div>
                                    {/* /row */}
                              </div>
                              {/* /container */}
                        </div>
                        {/* /SECTION */}

                        {/* Section */}
                        <div class="section main ">
                              {/* container */}
                              <div class="container">
                                    {/* row */}
                                    <div class="row">

                                          <div class="col-md-12">
                                                <div class="section-title text-center">
                                                      <h3 class="title">Produits connexes</h3>

                                                </div>
                                          </div>



                                          <div class='col-md-3 col-xs-6  w-100'>
                                                <ReactElasticCarousel
                                                      breakPoints={breakpoints}
                                                      pagination={false}

                                                      sx={{ width: " 100%" }}
                                                >
                                                      {products.map((product) => <ProductComponent addToCard={addToCard} product={product} />)}


                                                </ReactElasticCarousel>
                                          </div>
                                    </div>


                                    {/* product */}

                                    {/* /product */}

                              </div>
                              {/* /row */}

                        </div>
                        {/* /container */}
                  </div>


            </>

      )
}
