import 'bootstrap/dist/css/bootstrap.css'
import './themes/font.css'
// import 'boostrap'

import './App.css';
import './css/style.css'
import './css/accountbtn.css'
import classes from "./app.module.css"
import * as React from 'react';
// import Footer from './components/footerComponent.js/Footer';
// import Header from './components/headerComponent.js/Header';
import { Route, Routes } from 'react-router';
import Home from './components/homeComponent/Home';
import { NavLink } from 'react-router-dom/dist';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Box from '@mui/material/Box';
import Social from './components/social/Social';
import ShopComponent from './components/ShopComponent/ShopComponent';
import { useEffect } from 'react';
import ItemPreview from './components/itemPreview/ItemPreview';
import ColorPicker from './components/colorPicker/colorpicker';
import Header from './components/headerComponent/header';
import ProductSmall from './components/product/product'
import Footer from './components/footerComponent/footer';
import Admin from './components/Admin/AdminComponent/Admin';
import Login from './components/Login/Login';
import useSilverHook from './useSilverHook';
import db from "./firebase"
import { addDoc, collection, doc, onSnapshot, setDoc } from 'firebase/firestore';
import LoaderComponent from './components/utilities/loadingComponent/LoaderComponent';
import { CheckOut } from './components/checkout/CheckOut';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function App() {

  const [products,setproducts] = React.useState([
])
const [loading,setloading] = React.useState(true)
  const [loggedin, setloggedin] = React.useState(false)
  const [shopingBag, setshopingBag] = React.useState([])
  function addToCard(item) {
    let nitem = shopingBag
    let total = 0
    // let check = false
    for (let i = 0; i < nitem.length; i++) {
      if (nitem[i] === item) {
      toast.error("Item already added")
        return
      }
      total += Number(nitem[i].newPrice)
    }
    total += Number(item.newPrice)
    nitem.push(item)
    setshopingBag(nitem)
    console.log(shopingBag)
    setTotalPrice(total)
    setcardtotal(shopingBag.length)
      toast.success("Added succesfully")
   
  }
  const [cardtotal, setcardtotal] = React.useState(0)
  const [totalPrice, setTotalPrice] = React.useState(0)
  const [cardstate, setcardState] = React.useState(false);
  const clearcard = () => {
    setshopingBag([])
    setcardtotal(0)
  }
  function toggleCartDrawerx(event) {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setcardState(!cardstate);
  };

  const list = ({ anchor }) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 350 }}
      role="presentation"
      onClick={toggleCartDrawerx}
      onKeyDown={toggleCartDrawerx}
    >
      <div className={classes.cartContainer}>
        <div className={classes.cartHeader}>
          <div>SHORPING CART</div>
          <div className={classes.itemsCount}>{cardtotal}</div>
        </div>

        <div className={classes.cartBody + " overflow-y-scroll"}>
          <div style={{border:"1px solid red",color:"red",cursor:"pointer",textAlign:"center"}} onClick={clearcard} className='my-2 py-2 px-4'>Clear cart</div>
          {shopingBag.map((product) =>
            <ProductSmall product={product} />)}
        </div>
        <div className={classes.cartFooter}>
          <div className={classes.subTotalDisp}>
            <div className={classes.subTotal}>SUBTOTAL:</div>
            <div>{totalPrice} fcfa</div>
          </div>
          <div className={classes.viewCart}>
            {/* VIEW CART */}
          </div>
          <div style={{backgroundColor:"#e1e1e1"}} className={classes.checkoutbtn} onClick={()=>{}}><NavLink to={"/checkout"} >
            Passer la commande</NavLink ></div>
        </div>

      </div>
    </Box>
  );
  const [val,setval] = React.useState()
async function handleNew(){
  const docRef = doc(db,"eccomerce","id123")
  const payload = {name: "new name",value:"nvalue"}
  await setDoc(docRef,payload)

  // const collectionRef = collection(db,"eccomerce")
  // addDoc()

}

  useEffect(() => {
  // setproducts([...products,{man:"yup"}])
  onSnapshot(collection(db,"eccomerce"),(snapshot)=>{
    console.clear()
    // let data = []
    let data = snapshot.docs.map((doc)=>{
      return {...doc.data(),id:doc.id}
    })
    console.log(data)
    setproducts(data)
  setTimeout(() => {
    setloading(false)
  }, 2000);
  })
  
    window.scrollTo(0, 0)
  }, [])
  return (<>{ loggedin?<Admin products={products} setproducts={setproducts} setloggedin={setloggedin} />:<div className="App">
    <Header products={products} cardtotal={cardtotal} setcardState={setcardState} setcardtotal={setcardtotal} toggleCartDrawerx={toggleCartDrawerx} />
    <Routes>
      <Route path='/' element={<Home addToCard={addToCard} products={products} />} />
      <Route path='/shop' element={<ShopComponent  products={products} setproducts={setproducts}  addToCard={addToCard} />} />
      <Route path='/color' element={<ColorPicker />} />
      <Route path='/checkout' element={<CheckOut totalPrice={totalPrice} shopingBag={shopingBag}  products={products} cardtotal={cardtotal} />} />
      <Route path='/login' element={<Login setloggedin={setloggedin} />} />
      <Route path='/preview/:id' element={<ItemPreview addToCard={addToCard} products={products} />} />
    </Routes>
    <Footer />
    <div>
      <ToastContainer/>
      <React.Fragment>
        {/* <Button onClick={toggleCartDrawerx()}>{"RIGHT"}</Button> */}
        <SwipeableDrawer
          anchor={"right"}
          open={cardstate}
          onClose={toggleCartDrawerx}
          onOpen={toggleCartDrawerx}
        >
          {list("hello")}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
    <LoaderComponent loading={loading}/>
  </div>

}


</> );
}

export default App;
