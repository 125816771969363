import { Button } from '@mui/material';
import classes from './colorpicker.module.css'

import Avatar from '@mui/material/Avatar';

import Radio from '@mui/material/Radio';
import { useState } from 'react';
export default function ColorPicker(){
      const [btn,setbtn] = useState(false)
      function switchState(){
            setbtn(!btn)
      }
      const enabled={
            color:"red !important"
      }
      const disabled = {
            color:"grey !important"
      }
      return(
            <>
            <div className={classes.boxesContainer}>
                  <div className={classes.box +" " + classes.box1}>

                  <Radio sx={btn?enabled:disabled}  disabled={!btn}/>
                 <Button onClick={switchState} variant='outlined'>click me</Button>


                  </div>
                  <div className={classes.box +" " + classes.box2}></div>
                  <div className={classes.box +" " + classes.box3}></div>
                  <div className={classes.box +" " + classes.box4}></div>
                  <div className={classes.box +" " + classes.box5}></div>

            </div>
            

            </>
      )
}